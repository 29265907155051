import React from 'react';

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="34"
      fill="none"
      viewBox="0 0 120 34"
    >
      <path
        fill="#141414"
        d="M66.533 33.176c-1.283 0-2.428-.278-3.433-.834a6.397 6.397 0 01-2.342-2.374c-.556-1.005-.834-2.16-.834-3.465V16.14h4.395v9.721c0 1.07.31 1.925.93 2.567.642.642 1.498.962 2.567.962.728 0 1.38-.139 1.957-.417.6-.299 1.091-.716 1.476-1.25V16.14h4.396v16.715h-4.396v-1.347c-1.283 1.112-2.855 1.668-4.716 1.668zM88.343 33.176c-1.819 0-3.594-.331-5.326-.994a14.771 14.771 0 01-4.62-2.888l2.663-3.337c1.304 1.156 2.556 1.99 3.753 2.503 1.22.513 2.503.77 3.85.77.942 0 1.754-.107 2.439-.32.684-.236 1.208-.568 1.572-.995.385-.428.577-.93.577-1.508 0-.77-.278-1.358-.834-1.765-.556-.428-1.508-.76-2.855-.994l-4.46-.77c-1.904-.321-3.358-.995-4.363-2.022-.984-1.026-1.476-2.353-1.476-3.978 0-1.39.353-2.599 1.059-3.626.727-1.026 1.743-1.807 3.048-2.342 1.326-.556 2.898-.834 4.716-.834 1.647 0 3.283.278 4.909.834 1.647.556 3.101 1.326 4.363 2.31l-2.502 3.465c-2.353-1.796-4.717-2.695-7.09-2.695-.835 0-1.562.107-2.183.321-.62.214-1.101.514-1.443.899a1.92 1.92 0 00-.482 1.315c0 .684.246 1.22.738 1.604.492.364 1.316.642 2.47.834l4.236.706c2.246.364 3.925 1.08 5.037 2.15 1.112 1.048 1.668 2.438 1.668 4.17 0 1.455-.385 2.728-1.155 3.819-.77 1.069-1.871 1.903-3.304 2.502-1.412.578-3.08.866-5.005.866zM107.182 32.855V14.505h-7.571v-4.107h19.763v4.107h-7.539v18.351h-4.653z"
      />
      <circle cx="38.529" cy="17" r="11.142" fill="#fff" />
      <path
        fill="#FC812B"
        fillRule="evenodd"
        d="M54.637 17c0 4.577-1.9 8.71-4.956 11.652l2.988 4.524H38.366a16.131 16.131 0 01-11.739-5.148H16.396a1.561 1.561 0 110-3.122h7.95a16.085 16.085 0 01-1.413-3.359H7.037a1.561 1.561 0 010-3.122h15.31a16.375 16.375 0 01.028-3.14h-7.188a1.561 1.561 0 110-3.122h7.834a16.09 16.09 0 011.538-3.437H21.77a1.561 1.561 0 110-3.122h5.212a16.126 16.126 0 0111.48-4.78C47.394.824 54.636 8.066 54.636 17zM12.635 5.604a1.561 1.561 0 100 3.122h3.655a1.561 1.561 0 100-3.122h-3.655zm-4.98.134a1.561 1.561 0 100 3.122h.22a1.561 1.561 0 000-3.122h-.22zM1.56 12.163a1.561 1.561 0 000 3.123H10.8a1.561 1.561 0 100-3.123H1.56zm35.035-3.82c.174-.926.29-1.173.666-1.42.191-.127.393-.152 1.248-.152 1.481 0 1.687.153 1.913 1.426.145.814.174 1.012.284 1.08.043.027.097.034.174.05.139.028.559.194.934.37l.68.319.743-.514c.409-.283.87-.538 1.025-.567.438-.082.894.175 1.596.901.935.968.94 1.254.046 2.53l-.448.64.192.367c.105.201.288.627.406.946l.215.58.825.136c1.385.23 1.622.486 1.672 1.802.056 1.469-.213 1.813-1.607 2.058-.534.094-.873.194-.913.268-.077.143-.64 1.53-.698 1.719-.023.078.165.428.454.846.81 1.167.776 1.453-.301 2.506-1.033 1.01-1.297 1.027-2.52.155l-.667-.476-.555.263c-.306.145-.713.311-.907.369-.193.057-.38.162-.417.233-.036.07-.13.495-.21.943-.132.755-.165.837-.45 1.109-.356.341-.579.399-1.54.399-.572 0-.776-.035-1.07-.183-.477-.242-.623-.524-.785-1.51l-.135-.822-.66-.253c-.364-.14-.788-.322-.944-.405l-.285-.152-.694.475c-1.263.864-1.517.856-2.476-.075-.743-.721-.869-.928-.869-1.426 0-.316.072-.471.528-1.139l.528-.773-.316-.679c-.173-.373-.34-.797-.37-.94-.061-.291-.079-.3-.94-.434-.815-.128-1.262-.33-1.466-.665-.142-.233-.165-.41-.165-1.28 0-.863.023-1.046.16-1.27.229-.368.578-.527 1.5-.682l.805-.136.184-.51c.102-.28.28-.702.397-.937l.213-.427-.53-.775c-.47-.688-.528-.817-.528-1.165 0-.527.126-.739.846-1.422.67-.637 1.048-.808 1.5-.678.142.04.587.299.99.574.729.5.732.5.947.36.118-.078.533-.26.922-.407l.707-.266.166-.883z"
        clipRule="evenodd"
      />
      <path
        fill="#FF8E3F"
        d="M38.533 13.577A3.427 3.427 0 0035.111 17a3.427 3.427 0 003.422 3.423A3.426 3.426 0 0041.955 17a3.426 3.426 0 00-3.422-3.423zm0 6.085A2.665 2.665 0 0135.872 17a2.665 2.665 0 012.661-2.662A2.665 2.665 0 0141.195 17a2.665 2.665 0 01-2.662 2.662z"
      />
    </svg>
  );
}

export default Logo;
