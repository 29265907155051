import styled from 'styled-components';

export const InputFieldStyles = styled.div`
  label {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: var(--black-default, #000);
    font-weight: 600;
  }
  
  .labelContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .inputWrap {
    display: flex;
    align-items: center;

    .inputContainer {
      position: relative;

      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr;

      .showPasswordButton {
        position: absolute;
        background: none;
        right: 10px;
        top: 9px;
      }
    }

    input {
      font-size: 16px;
      background-color: inherit;
      box-sizing: border-box;
      padding: 10px 8px;
      transition: 0.3s all;
      font-family: 'Inter', sans-serif;
      outline: none;

      border-radius: var(--corners_btn, 4px);
      border: 1px solid var(--gray-light, #C2C9D1);
      background: var(--white-default, #FFF);

      &:hover {
        border: 1px solid var(--orange-2, #FEC9A4);
      }

      &:focus {
        border: 1px solid var(--orange-1, #FC812B);
      }


      &::placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: var(--black-light, rgba(0, 0, 0, 0.50));
      }

      &.filled {
        border-radius: var(--corners_btn, 4px);
        border: 1px solid var(--gray-light, #C2C9D1);
        background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
      }

      &.disabled {
        border: 1px solid var(--gray-light, #C2C9D1);
        background: var(--gray-lighter, linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), #C2C9D1);
      }
    }

    .childrenContainer {
      margin-bottom: 24px;
    }
  }

  .formErrorContainer {
    padding-top: 2px;

    display: flex;
    gap: 8px;

    color: #00000080;
  }

  .required {
    color: var(--black-light, rgba(0, 0, 0, 0.50));
  }
`;
