import React from 'react';
import TextWithDot from '../../components/other/textWithDot/TextWithDot';
import { TermsStyles } from './TermsStyles';
import LinksPath from '../../components/other/linksPath/LinksPath';
import Modal from '../../components/modal/Modal';
import ContactForm from '../../components/contactForm/ContactForm';

const background = (
  <svg width="1440" height="772" viewBox="0 0 1440 772" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-153.002 -156.892C-80.347 -189.063 23.4165 -171.516 93.9303 -115.25C149.635 -70.8045 183.356 -7.26202 242.705 33.0671C329.322 91.7801 440.211 83.1154 536.436 70.126C632.661 57.1366 742.043 46.0837 830.78 101.875C865.551 123.789 893.056 153.847 924.549 179.504C956.02 205.249 995.434 227.847 1034.25 227.062C1075.23 226.199 1104.45 200.081 1137.59 182.856C1200.99 149.704 1288.19 148.444 1365 179.597C1441.81 210.75 1506.43 273.547 1533.38 343.384" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-155.015 -138.341C-96.0578 -165.893 -15.3197 -160.019 51.0218 -126.303C64.8326 -119.281 78.0642 -111.094 90.4433 -101.721C126.65 -74.1333 154.372 -38.3522 185.159 -5.52172C202.24 12.8582 220.401 30.207 241.878 44.6726C247.567 48.4697 253.327 52.0025 259.158 55.2711C291.828 73.5048 327.401 83.3637 363.853 87.4176C402.002 91.6449 441.2 89.9247 479.422 86.3369C498.521 84.587 517.363 82.2959 535.782 80.0798C601.079 71.9803 672.433 65.7946 739.245 79.1534C771.302 85.6114 802.13 96.1752 830.798 113.332C865.601 134.122 893.302 162.44 924.672 186.554C931.58 191.812 938.661 196.927 946.234 201.704C972.908 218.795 1003.42 231.54 1033.72 230.538C1074.69 229.201 1104.42 203.692 1137.94 187.041C1142.42 184.847 1147.02 182.686 1151.79 180.857C1214.24 155.283 1294.65 157.386 1366.22 186.564C1373.24 189.397 1380.19 192.495 1386.92 195.911C1454.82 229.293 1510.79 286.836 1535.96 350.252" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-157.041 -119.699C-99.1699 -148.205 -19.3769 -143.812 47.1375 -111.748C61.0423 -105.079 74.3445 -97.1554 86.8175 -88.1339C123.658 -61.4139 152.24 -26.3444 183.473 6.32363C200.852 24.5951 219.137 41.9781 240.638 56.3559C246.327 60.1531 252.087 63.686 257.918 66.9546C290.838 85.2561 326.412 95.1153 362.88 98.6071C401.02 102.36 439.735 99.9434 478.105 96.3011C497.228 94.4632 516.171 92.2936 534.669 90.2873C600.2 82.8171 671.483 78.4057 738.498 92.0083C770.806 98.5338 801.728 108.746 830.357 125.042C865.317 144.743 893.089 171.287 924.233 193.736C931.087 198.696 938.136 203.426 945.655 207.904C972.111 223.805 1002.34 235.622 1032.45 234.289C1073.29 232.444 1103.65 207.577 1137.56 191.501C1142.16 189.34 1146.84 187.389 1151.49 185.526C1214.56 161.156 1294.8 164.35 1366.6 193.684C1373.62 196.517 1380.55 199.703 1387.43 203.064C1455.58 236.515 1512.02 293.808 1537.97 357.339" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-158.921 -101.113C-102.261 -130.607 -23.2881 -127.662 43.2741 -97.283C57.2494 -90.8777 70.6691 -83.3948 83.3377 -74.6037C120.687 -48.7845 150.254 -14.393 182.058 18.1463C199.734 36.3094 218.168 53.6383 239.794 68.0502C245.483 71.8474 251.243 75.3804 257.199 78.6828C290.244 97.0184 325.967 106.823 362.302 109.808C400.31 113.053 438.79 110.007 477.208 106.188C496.48 104.296 515.399 102.214 533.975 100.418C599.842 93.6985 671.078 90.9734 738.171 104.786C770.706 111.467 801.722 121.327 830.336 136.676C865.304 155.341 893.148 180.111 924.316 200.962C931.138 205.537 938.133 209.968 945.496 214.027C971.733 228.736 1001.69 239.539 1031.75 237.908C1072.61 235.501 1103.33 211.296 1137.73 195.916C1142.3 193.844 1147.1 191.926 1151.85 190.185C1215.56 166.931 1295.65 171.215 1367.56 200.671C1374.55 203.593 1381.62 206.724 1388.38 210.053C1456.93 243.517 1513.66 300.702 1540.26 364.315" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-160.927 -82.5591C-105.353 -113.007 -27.3251 -111.544 39.3865 -82.7289C53.4558 -76.6758 66.946 -69.4569 79.8337 -60.9842C117.668 -35.9779 148.244 -2.35241 180.619 30.058C198.467 48.0791 217.175 65.3877 238.902 79.9216C244.591 83.7189 250.477 87.2857 256.307 90.5545C289.478 108.924 325.349 118.675 361.7 121.097C399.725 123.781 437.845 120.071 476.263 116.252C495.558 114.272 514.556 112.4 533.233 110.725C599.335 104.635 670.625 103.718 737.82 117.653C770.581 124.49 801.59 133.876 830.439 148.344C865.439 165.885 893.479 188.915 924.421 208.101C931.213 212.29 938.177 216.336 945.484 220.097C971.527 233.527 1001.28 243.612 1031.18 241.561C1072.01 238.768 1103.26 215.083 1138.04 200.278C1142.71 198.327 1147.49 196.498 1152.37 194.79C1216.8 172.862 1296.75 178.149 1368.76 207.727C1375.88 210.683 1382.83 213.781 1389.7 217.143C1458.53 250.587 1515.71 307.61 1542.96 371.305" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-162.957 -63.9176C-108.469 -95.3195 -31.363 -95.4261 35.4981 -68.1752C49.6379 -62.3863 63.3472 -55.4859 76.2039 -47.399C114.547 -23.2937 146.109 9.65382 178.929 41.9018C197.074 59.8144 215.907 77.1571 237.658 91.6033C243.347 95.4007 249.233 98.9675 255.188 102.27C288.484 120.674 324.527 130.282 360.848 132.319C398.763 134.406 436.626 130.155 474.943 126.215C494.261 124.146 513.361 122.396 532.139 120.843C598.452 115.47 669.82 116.274 737.093 130.419C769.956 137.377 801.184 146.445 830.018 159.965C865.026 176.47 893.286 197.672 924.127 215.226C930.888 219.029 937.797 222.777 945.074 226.152C970.898 238.391 1000.38 247.461 1030.11 245.078C1070.92 241.811 1102.58 218.613 1137.85 204.504C1142.65 202.587 1147.4 200.846 1152.36 199.348C1217.43 178.536 1297.23 184.913 1369.43 214.736C1376.55 217.691 1383.5 220.789 1390.38 224.151C1459.46 257.663 1517.08 314.525 1545.13 378.247" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-164.964 -45.3625C-111.687 -77.752 -35.2759 -79.273 31.508 -53.7418C45.6167 -48.3387 59.5451 -41.7568 72.5974 -33.9003C111.449 -10.6961 143.996 21.5733 177.387 53.6924C195.705 71.4629 214.811 88.7852 236.687 103.265C242.502 107.097 248.262 110.63 254.241 113.844C287.662 132.282 323.729 141.803 360.167 143.399C397.949 144.979 435.578 140.098 473.942 135.981C493.386 133.946 512.485 132.196 531.342 130.853C597.866 126.198 669.311 128.721 736.663 143.076C769.752 150.19 801.098 158.818 829.893 171.479C865.058 186.893 893.366 206.409 924.106 222.332C930.812 225.836 937.69 229.198 944.913 232.276C970.519 243.323 999.84 251.499 1029.38 248.785C1070.07 245.01 1102.36 222.454 1138.11 209.042C1142.89 207.212 1147.87 205.627 1152.82 204.129C1218.38 184.487 1298.28 192.023 1370.46 221.934C1377.58 224.89 1384.53 227.988 1391.53 231.384C1461.01 264.909 1518.96 321.576 1547.65 385.379" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-166.969 -26.8086C-114.802 -60.0641 -39.2881 -63.2431 27.6217 -39.188C41.8244 -34.1372 55.8233 -27.8194 69.1181 -20.3695C108.604 1.96735 142.011 33.525 175.973 65.5152C194.588 83.1773 213.695 100.5 235.844 114.96C241.659 118.791 247.544 122.358 253.523 125.573C287.194 144.078 323.285 153.511 359.739 154.545C397.514 155.651 434.807 150.019 473.171 145.902C492.638 143.779 511.839 142.15 530.774 141.017C597.532 136.991 668.883 141.377 736.462 155.888C769.778 163.158 801.116 171.311 829.998 183.146C865.171 197.525 893.699 215.212 924.213 229.469C930.888 232.588 937.735 235.564 944.903 238.343C970.189 248.077 999.323 255.448 1028.84 252.348C1069.51 248.099 1102.19 226.117 1138.44 213.313C1143.32 211.606 1148.3 210.02 1153.36 208.644C1219.54 190.206 1299.3 198.745 1371.69 228.901C1378.81 231.856 1385.88 234.988 1392.76 238.35C1462.49 271.944 1520.88 328.448 1550.37 392.279" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-168.998 -8.1648C-117.917 -42.3744 -43.2238 -47.0016 23.7338 -24.6326C38.0071 -19.8459 52.1235 -13.9684 65.4888 -6.7826C105.483 14.6531 139.876 45.5326 174.284 77.3603C193.219 94.8259 212.451 112.183 234.6 126.643C240.415 130.474 246.324 133.953 252.28 137.256C286.099 155.707 322.315 165.174 358.761 165.734C396.427 166.244 433.463 160.07 471.874 155.777C491.365 153.566 510.668 152.059 529.681 151.135C596.65 147.827 668.079 153.934 735.735 168.655C769.302 175.992 800.71 183.881 829.577 194.768C864.908 208.056 893.357 224.024 923.92 236.595C930.563 239.327 937.356 242.006 944.493 244.399C969.561 252.942 998.406 259.385 1027.74 255.953C1068.28 251.196 1101.59 229.856 1138.23 217.628C1143.21 216.042 1148.17 214.544 1153.35 213.202C1220.17 195.88 1299.75 205.598 1372.24 235.876C1379.36 238.832 1386.41 242.051 1393.44 245.359C1463.44 278.933 1522.25 335.364 1552.39 399.276" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-171.01 10.3914C-121.14 -24.8059 -47.2434 -30.9696 19.7385 -10.1986C34.1058 -5.76416 48.2927 -0.150875 61.8771 6.71642C102.38 27.251 137.758 57.4522 172.737 89.151C191.969 106.508 211.35 123.811 233.624 138.305C239.439 142.136 245.348 145.615 251.429 148.952C285.374 167.438 321.738 176.85 358.052 176.902C395.585 176.904 432.387 170.101 470.696 165.686C490.336 163.42 509.615 162.001 528.707 161.288C595.91 158.609 667.393 166.524 735.128 181.455C768.921 188.948 800.447 196.397 829.275 206.424C864.614 218.676 893.284 232.815 923.596 243.808C930.209 246.155 936.947 248.535 943.904 250.597C968.754 257.948 997.459 263.41 1026.61 259.646C1067.15 254.415 1100.96 233.683 1137.96 222.118C1142.94 220.532 1148 219.156 1153.14 217.99C1220.58 201.872 1300.15 212.626 1372.74 243.027C1379.86 245.983 1386.91 249.203 1393.94 252.511C1464.31 286.186 1523.47 342.333 1554.26 406.326" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-173.015 28.9473C-124.231 -7.20411 -51.1545 -14.8162 15.8519 4.35672C30.2897 8.527 44.5706 13.788 58.3741 20.3368C99.5109 40.0038 135.749 69.4932 171.174 101.029C190.578 118.244 210.209 135.615 232.632 150.055C238.471 153.798 244.481 157.399 250.437 160.702C284.507 179.222 321.02 188.58 357.35 188.07C394.899 187.51 431.341 180.043 469.674 175.54C489.337 173.186 508.844 171.923 527.888 171.385C595.302 169.424 666.863 179.06 734.7 194.113C768.72 201.761 800.363 208.77 829.176 217.849C864.547 228.977 893.288 241.341 923.625 250.736C930.183 252.785 936.914 254.691 943.816 256.455C968.447 262.615 996.863 267.149 1025.96 263.087C1066.49 257.382 1100.71 237.137 1138.2 226.268C1143.28 224.804 1148.44 223.549 1153.58 222.383C1221.63 207.47 1301.05 219.315 1373.86 249.872C1380.98 252.828 1388.16 256.081 1395.19 259.389C1465.84 293.045 1525.29 349.084 1557 413.139" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-174.891 47.538C-127.193 10.4324 -55.367 2.48341 12.1177 18.8585C26.72 22.4122 41.0244 27.5853 54.8984 33.8699C96.544 52.6356 133.768 81.4468 169.763 112.854C189.465 129.961 209.244 147.277 231.644 161.805C237.482 165.549 243.493 169.15 249.574 172.487C283.769 191.04 320.431 200.344 356.753 199.36C394.17 198.292 430.378 190.196 468.735 185.604C488.398 183.25 508.029 182.021 527.152 181.693C594.777 180.45 666.415 191.806 734.33 207.069C768.577 214.873 800.19 221.496 829.237 229.694C864.742 239.82 893.554 250.409 923.814 258.084C930.341 259.747 937.017 261.356 943.888 262.733C968.176 267.667 996.428 271.308 1025.37 266.827C1065.77 260.613 1100.62 241.01 1138.49 230.716C1143.55 229.34 1148.83 228.119 1154.07 227.075C1222.77 213.278 1302.03 226.214 1374.92 256.982C1382.14 260.059 1389.22 263.191 1396.24 266.499C1467.17 300.135 1527.07 356.012 1559.43 420.148" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-176.927 66.1826C-130.441 28.0892 -58.9571 17.404 8.22302 33.4141C22.8488 36.8798 37.2943 41.5244 51.3874 47.4904C93.5416 65.3548 131.751 93.4877 168.317 124.766C188.167 141.819 208.221 159.115 230.769 173.589C236.732 177.366 242.618 180.933 248.722 184.182C283.168 202.804 319.853 212.02 356.191 210.473C393.476 208.897 429.325 200.137 467.853 195.403C487.54 192.961 507.273 191.854 526.474 191.736C594.333 191.122 665.901 204.253 734.019 219.76C768.493 227.72 800.223 233.902 829.232 241.241C864.768 250.242 893.652 259.057 923.787 265.188C930.283 266.464 936.904 267.775 943.721 268.855C967.791 272.597 995.903 275.256 1024.67 270.443C1065.06 263.755 1100.29 244.727 1138.64 235.13C1143.8 233.875 1149.07 232.743 1154.41 231.82C1223.72 219.228 1302.95 233.289 1375.94 264.178C1383.17 267.256 1390.24 270.388 1397.24 273.784C1468.54 307.521 1528.76 363.203 1561.92 427.366" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-178.934 84.7395C-133.557 45.7799 -62.9716 33.4363 4.20944 47.9362C18.9058 51.1377 33.4688 55.3419 47.7574 61.0774C90.4203 78.0405 129.615 105.495 166.651 136.523C186.798 153.467 207 170.709 229.673 185.217C235.637 188.994 241.647 192.595 247.752 195.844C282.322 214.5 319.133 223.75 355.487 221.64C392.788 219.503 428.277 210.079 466.806 205.345C486.641 202.849 506.351 201.829 525.653 201.833C593.724 201.937 665.369 216.789 733.566 232.506C768.266 240.621 800.114 246.363 829.084 252.842C864.777 260.752 893.709 267.881 923.767 272.291C930.208 273.27 936.798 274.194 943.584 274.888C967.459 277.351 995.259 279.17 1023.97 274.059C1064.23 266.863 1100.09 248.477 1138.95 239.488C1144.24 238.268 1149.48 237.223 1154.92 236.422C1224.72 225.001 1303.94 240.098 1377.14 271.232C1384.37 274.31 1391.57 277.476 1398.57 280.872C1470.14 314.589 1530.81 370.109 1564.62 434.354" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-180.943 103.302C-136.652 63.3873 -66.863 49.5066 0.34258 62.4083C15.133 65.2575 29.8916 69.2313 44.2743 74.6147C87.5711 90.7104 127.627 117.453 165.233 148.353C185.678 165.188 205.903 182.343 228.702 196.885C234.665 200.663 240.676 204.264 246.78 207.513C281.476 226.203 318.31 235.365 354.657 232.782C391.825 230.136 427.081 220.083 465.532 215.139C485.391 212.554 505.202 211.656 524.582 211.869C592.888 212.603 664.587 229.264 732.862 245.192C767.79 253.463 799.606 258.819 828.687 264.383C864.389 271.257 893.391 276.611 923.497 279.335C929.907 279.928 936.466 280.466 943.072 280.829C966.729 282.099 994.24 282.991 1022.79 277.46C1063.07 269.701 1099.3 251.978 1138.64 243.686C1143.9 242.553 1149.37 241.664 1154.81 240.863C1225.25 230.558 1304.29 246.835 1377.6 278.092C1384.82 281.17 1391.99 284.424 1399.02 287.732C1470.85 321.518 1531.96 376.876 1566.42 441.204" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-182.982 121.954C-139.902 81.0507 -70.9093 65.6329 -3.55404 76.9701C11.3304 79.467 26.1596 83.1766 40.6364 88.2078C84.4421 103.402 125.483 129.468 163.536 160.205C184.153 176.899 204.628 194.122 227.576 208.611C233.539 212.388 239.573 215.902 245.779 219.272C280.624 237.909 317.584 247.105 353.947 243.96C390.982 240.806 426.004 230.122 464.479 225.09C484.361 222.417 504.274 221.64 523.733 222.064C592.25 223.515 664.027 241.898 732.404 257.949C767.582 266.287 799.493 271.291 828.535 275.995C864.394 281.779 893.467 285.358 923.324 286.504C929.703 286.71 936.208 286.951 942.783 286.927C966.096 286.972 993.467 286.882 1021.94 281.14C1062.21 272.908 1098.97 255.704 1138.67 248.075C1144.04 247.064 1149.5 246.175 1155.05 245.496C1226.11 236.395 1305.12 253.798 1378.55 285.09C1385.77 288.168 1392.94 291.422 1400.1 294.764C1472.3 328.653 1533.83 383.938 1569.09 448.295" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-184.992 140.52C-142.999 98.6608 -74.8018 81.7056 -7.57044 91.4988C7.28302 93.6098 22.3314 97.0008 37.0274 101.714C81.3419 116.007 123.369 141.394 161.992 172.004C182.906 188.59 203.531 205.759 226.604 220.282C232.567 224.06 238.726 227.607 244.932 230.978C280.027 249.683 317.011 258.791 353.367 255.171C390.395 251.543 425.081 240.107 463.556 235.075C483.564 232.435 503.477 231.659 523.014 232.292C591.742 234.462 663.597 254.566 732.053 270.827C767.457 279.322 799.486 283.885 828.513 287.641C864.404 292.3 893.525 294.192 923.43 293.651C929.755 293.56 936.228 293.414 942.749 293.093C965.844 291.946 993.051 290.962 1021.25 284.766C1061.38 276.025 1098.77 259.463 1138.86 252.409C1144.33 251.52 1149.77 250.719 1155.29 250.128C1226.99 242.144 1305.84 260.638 1379.35 292.141C1386.57 295.219 1393.87 298.507 1400.9 301.816C1473.4 335.597 1535.26 390.688 1571.16 455.129" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-187.012 159.086C-146.105 116.271 -78.8292 97.7452 -11.4482 105.974C3.47589 107.82 18.6183 110.859 33.5334 115.253C78.482 128.679 121.369 153.355 160.564 183.836C181.775 200.314 202.525 217.517 225.621 231.953C231.585 235.731 237.744 239.278 243.95 242.649C279.171 261.389 316.303 270.443 352.651 266.349C389.571 262.124 423.999 250.147 462.521 244.937C482.553 242.21 502.568 241.555 522.183 242.399C591.146 245.198 662.93 267.078 731.59 283.583C767.221 292.234 799.368 296.357 828.356 299.252C864.255 302.875 893.574 303.026 923.276 300.731C929.57 300.253 935.988 299.81 942.478 299.102C965.355 296.763 992.273 294.851 1020.41 288.357C1060.54 279.141 1098.32 263.154 1138.89 256.797C1144.35 255.908 1149.9 255.228 1155.52 254.759C1227.71 247.946 1306.54 267.566 1380.27 299.226C1387.62 302.338 1394.79 305.592 1401.95 308.935C1474.7 342.785 1537 397.715 1573.7 462.184" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-189.049 177.742C-149.352 133.938 -82.7243 113.82 -15.3421 120.537C-0.323975 122.032 14.9125 124.718 29.8983 128.848C75.3557 141.373 119.205 165.459 158.87 195.69C180.253 212.026 201.277 229.209 224.475 243.767C230.563 247.579 236.598 251.093 242.952 254.41C278.298 273.183 315.579 282.184 351.944 277.527C388.73 272.794 422.925 260.186 461.346 254.855C481.401 252.039 501.517 251.507 521.211 252.56C590.385 256.077 662.248 279.679 730.986 296.395C766.844 305.201 798.983 308.851 828.183 310.953C864.24 313.485 893.481 311.915 923.207 308.02C929.47 307.157 935.858 306.327 942.293 305.321C964.951 301.79 991.605 298.862 1019.69 292.07C1059.69 282.346 1098.09 267.001 1139.05 261.218C1144.62 260.451 1150.26 259.893 1155.88 259.424C1228.72 253.727 1307.51 274.473 1381.32 306.344C1388.65 309.544 1395.85 312.711 1403 316.054C1476.02 349.885 1538.77 404.654 1576.12 469.206" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-190.94 196.342C-152.329 151.583 -86.6227 129.893 -19.2393 135.1C-4.12708 136.242 11.18 138.664 26.3849 142.476C72.3513 154.099 117.187 177.507 157.422 207.609C179.103 223.837 200.275 240.967 223.598 255.559C229.687 259.371 235.846 262.919 242.076 266.202C277.547 285.01 314.977 293.957 351.334 288.825C388.114 283.618 421.973 270.258 460.417 264.839C480.621 261.968 500.714 261.524 520.611 262.821C590.145 267.001 661.937 292.38 730.754 309.306C766.839 318.269 799.072 321.566 828.257 322.719C864.346 324.127 893.76 320.903 923.409 315.287C929.617 314.126 935.974 312.91 942.354 311.606C964.67 306.848 991.285 303.06 1019.07 295.902C1059.05 285.703 1097.85 270.933 1139.31 265.759C1144.98 265.114 1150.62 264.556 1156.35 264.208C1229.8 259.717 1308.43 281.554 1382.35 313.549C1389.67 316.749 1396.87 319.916 1404.15 323.292C1477.55 357.226 1540.62 411.8 1578.62 476.435" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-192.947 214.91C-155.446 169.284 -90.5123 145.968 -23.1043 149.576C-7.89801 150.366 7.5032 152.436 22.9037 156.016C69.5041 166.772 115.2 189.468 156.006 219.442C177.859 235.528 199.157 252.692 222.629 267.23C228.718 271.043 234.877 274.59 241.232 277.907C276.828 296.75 314.407 305.643 350.655 299.915C387.326 294.111 420.927 280.209 459.396 274.701C479.623 271.743 499.818 271.42 519.793 272.927C589.538 277.825 661.408 304.926 730.327 321.975C766.514 331.059 798.99 333.949 828.137 334.243C864.382 334.559 893.844 329.648 923.268 322.365C929.445 320.818 935.747 319.304 941.971 317.58C964.068 311.63 990.395 306.913 1018.15 299.369C1058.02 288.574 1097.43 274.534 1139.37 270.057C1145.02 269.5 1150.77 269.064 1156.59 268.838C1230.69 265.463 1309.16 288.393 1383.28 320.632C1390.61 323.833 1397.91 327.121 1405.08 330.376C1478.76 364.29 1542.27 418.704 1581.05 483.455" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-194.99 233.566C-158.7 186.95 -94.5396 162.008 -27.0053 164.139C-11.7284 164.664 3.76687 166.381 19.2615 169.61C66.3708 179.464 112.927 201.448 154.305 231.293C176.455 247.271 197.901 264.382 221.499 278.954C227.588 282.767 233.747 286.315 240.102 289.632C275.949 308.543 313.551 317.347 349.792 311.145C386.455 304.867 419.698 290.301 458.214 284.616C478.464 281.569 498.761 281.368 518.838 282.997C588.794 288.614 660.742 317.436 729.74 334.696C766.154 343.937 798.748 346.386 827.879 345.731C864.133 345.01 893.791 338.357 923.138 329.353C929.26 327.508 935.531 325.607 941.725 323.497C963.603 316.355 989.665 310.622 1017.34 302.867C1057.21 291.597 1097.02 278.044 1139.45 274.264C1145.22 273.741 1150.94 273.393 1156.87 273.289C1231.48 270.997 1310.03 295.174 1384.27 327.449C1391.6 330.65 1398.9 333.938 1406.2 337.227C1480.15 371.121 1544.09 425.462 1583.53 490.208" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-197.002 252.136C-161.799 204.564 -98.4343 178.084 -30.8988 178.704C-15.5279 178.877 0.0615173 180.242 15.7518 183.24C63.3935 192.104 110.912 213.497 152.861 243.215C175.184 259.05 196.88 276.229 220.626 290.748C226.739 294.472 233 298.142 239.354 301.459C275.35 320.316 313.055 329.243 349.312 322.478C385.842 315.692 418.749 300.373 457.265 294.689C477.641 291.675 497.938 291.474 518.093 293.313C588.284 299.56 660.162 330.159 729.363 347.664C766.027 356.971 798.59 359.035 827.808 357.553C864.095 355.707 893.8 347.367 923.195 336.675C929.286 334.443 935.526 332.156 941.641 329.836C963.302 321.501 989.074 314.84 1016.59 306.665C1056.33 294.887 1096.77 281.976 1139.59 278.771C1145.33 278.336 1151.28 278.143 1157.09 278.006C1232.31 276.919 1310.73 302.1 1385.05 334.586C1392.38 337.787 1399.68 341.076 1406.98 344.364C1481.3 378.361 1545.59 432.419 1585.81 497.282" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-199.015 270.704C-164.897 222.177 -102.431 194.037 -34.8941 193.146C-19.429 192.966 -3.64398 194.1 12.1404 196.746C60.4161 204.741 108.772 225.511 151.19 254.978C173.81 270.706 195.656 287.83 219.402 302.35C225.514 306.074 231.799 309.656 238.13 313.062C274.251 331.953 312.104 340.826 348.378 333.498C384.901 326.237 417.449 310.254 456.012 304.393C476.412 301.292 496.81 301.213 517.044 303.262C587.446 310.227 659.401 342.548 728.681 360.263C765.572 369.727 798.253 371.35 827.433 369.007C863.876 366.07 893.63 356.041 922.798 343.683C928.859 341.064 935.044 338.479 941.128 335.773C962.571 326.245 988.078 318.568 1015.52 310.183C1055.25 297.93 1096.2 285.627 1139.37 283.086C1145.22 282.772 1151.17 282.58 1157.07 282.564C1232.95 282.595 1311.18 308.956 1385.73 341.599C1393.06 344.8 1400.48 348.122 1407.76 351.499C1482.36 385.477 1547.06 439.461 1587.96 504.319" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-201.057 289.362C-168.15 239.846 -106.457 210.08 -38.8173 207.799C-23.2581 207.267 -7.37897 208.048 8.49951 210.341C57.2842 217.435 106.626 237.527 149.639 266.777C172.556 282.396 194.55 299.467 218.422 314.021C224.534 317.745 230.819 321.327 237.299 324.679C273.545 343.604 311.547 352.423 347.813 344.62C384.228 336.763 416.518 320.238 455.105 314.289C475.528 311.099 496.028 311.142 516.34 313.4C586.977 320.996 658.987 355.128 728.345 373.053C765.464 382.673 798.262 383.855 827.403 380.652C863.878 376.59 893.805 364.907 922.896 350.826C928.902 347.91 935.057 344.939 941.11 341.846C962.334 331.125 987.678 322.554 1014.96 313.748C1054.56 300.987 1096.02 289.293 1139.7 287.361C1145.67 287.08 1151.58 287.065 1157.58 287.17C1233.97 288.285 1312.3 315.806 1386.93 348.66C1394.38 351.894 1401.68 355.183 1408.96 358.561C1483.8 392.607 1548.96 446.43 1590.5 511.37" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-203.067 307.932C-171.247 257.46 -110.349 226.156 -42.6854 222.275C-27.1573 221.357 -11.0824 221.908 5.01522 223.882C54.3089 230.074 104.637 249.487 148.22 278.608C171.31 294.086 193.554 311.224 217.551 325.812C223.788 329.571 230.073 333.153 236.428 336.47C272.8 355.431 310.951 364.195 347.084 355.884C383.366 347.519 415.446 330.275 454.033 324.325C474.605 321.081 495.082 321.212 515.472 323.681C586.321 331.994 658.408 367.849 727.845 385.985C765.191 395.76 797.958 396.556 827.334 392.471C863.818 387.372 893.941 373.947 922.83 358.111C928.805 354.808 934.78 351.505 940.778 348.114C961.659 336.167 986.839 326.701 1013.94 317.563C1053.56 304.239 1095.5 293.242 1139.57 291.885C1145.52 291.693 1151.55 291.711 1157.65 291.938C1234.66 294.258 1312.83 322.872 1387.56 355.849C1395.01 359.083 1402.31 362.372 1409.72 365.783C1484.96 399.844 1550.44 453.471 1592.75 518.528" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-205.085 326.502C-174.351 275.074 -114.352 242.11 -46.6867 236.718C-31.0645 235.447 -14.8955 235.645 1.39783 237.389C51.2004 242.679 102.514 261.413 146.544 290.372C169.907 305.829 192.199 322.792 216.321 337.414C222.558 341.173 228.843 344.755 235.323 348.107C271.945 367.135 310.097 375.9 346.27 366.938C382.544 358.098 414.265 340.19 452.774 334.03C473.37 330.697 493.949 330.95 514.417 333.629C585.477 342.661 657.642 380.238 727.181 398.496C764.753 408.428 797.639 408.783 826.976 403.837C863.617 397.647 893.789 382.533 922.577 365.064C928.497 361.462 934.441 357.773 940.408 353.995C961.071 340.855 985.986 330.373 1013.03 320.937C1052.52 307.103 1094.99 296.627 1139.52 296.056C1145.57 295.986 1151.7 296.125 1157.81 296.353C1235.45 299.79 1313.47 329.496 1388.41 362.718C1395.83 366.041 1403.26 369.364 1410.56 372.653C1486.08 406.694 1551.98 460.248 1594.97 525.299" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-206.99 345.195C-177.491 292.866 -118.241 258.187 -50.4729 251.405C-34.7565 249.781 -18.517 249.715 -2.00447 251.14C48.4557 255.474 100.607 273.584 145.208 302.414C168.743 317.729 191.308 334.672 215.556 349.328C221.793 353.087 228.078 356.669 234.558 360.021C271.306 379.084 309.606 387.794 345.772 378.358C382.039 369.043 413.4 350.47 451.934 344.222C472.53 340.889 493.233 341.176 513.78 344.065C585.074 353.727 657.169 393.081 726.912 411.584C764.711 421.672 797.613 421.464 827.037 415.691C863.733 408.287 894.077 391.519 922.765 372.416C928.654 368.428 934.692 364.386 940.48 360.276C961.049 345.977 985.55 334.532 1012.42 324.764C1051.77 310.422 1094.88 300.588 1139.91 300.627C1146.07 300.678 1152.2 300.818 1158.41 301.167C1236.59 305.6 1314.66 336.642 1389.59 369.865C1397.02 373.188 1404.44 376.511 1411.74 379.8C1487.52 413.91 1553.88 467.215 1597.65 532.382" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-209.007 363.767C-180.594 310.482 -122.242 274.142 -54.3478 265.883C-38.5609 263.994 -22.2038 263.487 -5.62065 264.648C45.372 267.991 98.4617 285.599 143.657 314.212C167.49 329.419 190.181 346.396 214.577 360.998C220.815 364.757 227.224 368.373 233.705 371.725C270.578 390.822 309.027 399.479 345.208 389.479C381.343 379.656 412.494 360.365 451.051 354.028C471.671 350.607 492.453 351.103 513.124 354.026C584.63 364.407 656.803 405.484 726.625 424.198C764.651 434.441 797.646 433.881 827.056 427.159C863.761 418.717 894.301 400.207 922.786 379.347C928.645 374.973 934.628 370.633 940.385 366.137C960.611 350.611 984.972 338.183 1011.79 328.116C1051.03 313.178 1094.62 304.041 1140.17 304.688C1146.32 304.74 1152.53 305.089 1158.84 305.561C1237.64 311.199 1315.57 343.246 1390.71 376.713C1398.14 380.036 1405.56 383.36 1412.99 386.683C1489.04 420.773 1555.82 474.004 1600.26 539.165" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-211.024 382.338C-183.822 328.063 -126.267 290.186 -58.2227 280.36C-42.3416 278.118 -25.9139 277.347 -9.11162 278.189C42.39 280.63 96.4655 297.559 142.107 326.01C166.237 341.108 189.077 358.031 213.473 372.634C219.711 376.393 226.121 380.009 232.601 383.361C269.623 402.404 308.197 411.095 344.372 400.62C380.397 390.2 411.291 370.367 449.872 363.942C470.64 360.466 491.399 361.051 512.149 364.184C583.889 375.195 656.093 418.171 726.142 437.041C764.418 447.352 797.508 446.439 826.879 438.856C863.616 429.289 894.329 409.124 922.714 386.63C928.642 381.992 934.344 377.197 940.172 372.437C960.179 355.718 984.252 342.361 1011.01 331.996C1050.25 316.583 1094.35 308.055 1140.25 309.366C1146.51 309.539 1152.71 309.889 1159 310.448C1238.45 317.204 1316.2 350.432 1391.56 384.056C1398.99 387.379 1406.42 390.702 1413.84 394.026C1490.27 428.218 1557.4 481.165 1602.61 546.443" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-213.062 400.997C-186.946 345.767 -130.391 306.107 -62.2675 294.979C-46.4175 292.352 -29.7705 291.261 -12.7725 291.872C39.2381 293.411 94.2995 309.661 140.512 337.984C164.815 352.939 187.904 369.931 212.449 384.479C218.687 388.239 225.097 391.855 231.726 395.152C268.998 414.264 307.471 422.832 343.639 411.883C379.657 400.989 410.191 380.491 448.694 373.856C469.486 370.292 490.347 370.998 511.175 374.341C583.127 386.071 655.409 430.77 725.536 449.85C764.04 460.317 797.247 458.964 826.579 450.52C863.474 439.861 894.235 418.007 922.417 393.757C928.315 388.732 933.962 383.64 939.758 378.492C959.548 360.58 983.456 346.329 1009.94 335.51C1049.16 319.621 1093.77 311.702 1140.06 313.589C1146.42 313.884 1152.73 314.356 1159.01 314.915C1239.07 322.877 1316.82 357.143 1392.14 390.944C1399.56 394.268 1407.12 397.625 1414.54 400.948C1491.24 435.12 1558.79 487.995 1604.66 553.355" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-215.077 419.57C-190.047 363.384 -134.288 322.185 -66.14 309.457C-1.09664 297.31 76.9378 312.38 138.988 349.694C169.826 368.3 198.551 390.306 230.75 406.823C268.148 425.968 306.77 434.483 342.953 422.971C378.839 411.568 409.162 390.351 447.666 383.716C540.546 367.729 634.507 437.734 725.079 462.606C822.406 489.297 885.371 437.662 939.618 384.528C992.028 333.261 1074 309.897 1159.4 319.484C1248.04 329.473 1333.03 371.266 1415.62 407.972C1492.57 442.213 1560.44 494.892 1607.1 560.28" stroke="#00C8CB" strokeMiterlimit="10" />
  </svg>
);

const backgroundTablet = (
  <svg width="768" height="562" viewBox="0 0 768 562" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-199.967 -53.6923C-151.87 -74.9895 -83.1794 -63.3735 -36.4999 -26.1258C0.376044 3.29663 22.699 45.3612 61.9877 72.0587C119.327 110.926 192.735 105.19 256.435 96.5913C320.135 87.9924 392.544 80.6755 451.287 117.609C474.305 132.115 492.513 152.014 513.362 168.999C534.195 186.042 560.287 201.001 585.982 200.481C613.111 199.91 632.453 182.621 654.395 171.218C696.365 149.271 754.09 148.437 804.938 169.06C855.785 189.683 898.561 231.254 916.4 277.486" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-201.297 -41.4118C-162.268 -59.6514 -108.82 -55.7626 -64.9022 -33.4427C-55.7597 -28.7946 -47.0004 -23.3747 -38.8056 -17.1695C-14.8368 1.09296 3.51446 24.7797 23.8953 46.5132C35.203 58.6805 47.2252 70.1653 61.4425 79.7413C65.2089 82.2549 69.022 84.5936 72.8818 86.7574C94.5089 98.828 118.058 105.354 142.189 108.038C167.443 110.837 193.392 109.698 218.695 107.323C231.338 106.164 243.811 104.648 256.005 103.181C299.23 97.8187 346.466 93.7239 390.695 102.567C411.917 106.842 432.325 113.836 451.302 125.193C474.341 138.956 492.679 157.702 513.446 173.666C518.019 177.146 522.706 180.533 527.72 183.694C545.378 195.009 565.578 203.446 585.632 202.783C612.756 201.898 632.434 185.011 654.63 173.988C657.591 172.535 660.635 171.105 663.798 169.894C705.139 152.965 758.366 154.357 805.747 173.672C810.393 175.548 814.993 177.598 819.448 179.86C864.401 201.958 901.449 240.051 918.115 282.032" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-202.638 -29.0712C-164.328 -47.9422 -111.506 -45.0342 -67.474 -23.8078C-58.2692 -19.3928 -49.4632 -14.1477 -41.2062 -8.1756C-16.8179 9.51281 2.10292 32.7285 22.7788 54.3544C34.2833 66.45 46.3883 77.9573 60.6212 87.4753C64.3876 89.989 68.2007 92.3277 72.0605 94.4916C93.8533 106.607 117.403 113.134 141.544 115.445C166.793 117.93 192.422 116.33 217.823 113.919C230.482 112.702 243.022 111.266 255.267 109.938C298.648 104.992 345.837 102.072 390.2 111.077C411.588 115.397 432.058 122.157 451.01 132.945C474.153 145.986 492.538 163.559 513.155 178.42C517.692 181.703 522.359 184.834 527.336 187.799C544.85 198.325 564.859 206.148 584.794 205.265C611.83 204.044 631.927 187.582 654.377 176.94C657.421 175.509 660.516 174.218 663.596 172.985C705.346 156.852 758.469 158.966 806 178.385C810.647 180.261 815.231 182.369 819.784 184.595C864.902 206.739 902.261 244.666 919.44 286.723" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-203.884 -16.7674C-166.375 -36.2921 -114.096 -34.3426 -70.0322 -14.2321C-60.7807 -9.99189 -51.897 -5.03829 -43.5105 0.781373C-18.7855 17.8734 0.787686 40.6403 21.8415 62.181C33.5426 74.2048 45.746 85.6764 60.0618 95.2169C63.8282 97.7306 67.6413 100.069 71.5839 102.256C93.4595 114.394 117.108 120.884 141.161 122.86C166.322 125.009 191.796 122.992 217.228 120.464C229.986 119.211 242.51 117.833 254.807 116.644C298.41 112.196 345.568 110.392 389.983 119.536C411.521 123.959 432.053 130.486 450.995 140.647C474.144 153.002 492.576 169.4 513.209 183.203C517.725 186.232 522.356 189.165 527.23 191.852C544.599 201.59 564.433 208.741 584.332 207.661C611.378 206.068 631.714 190.044 654.484 179.863C657.512 178.491 660.69 177.222 663.837 176.069C706.012 160.675 759.031 163.511 806.63 183.011C811.261 184.945 815.943 187.018 820.414 189.221C865.796 211.374 903.352 249.23 920.96 291.341" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-205.211 -4.48479C-168.422 -24.641 -116.768 -23.6724 -72.6058 -4.59719C-63.2921 -0.590117 -54.3617 4.1887 -45.8301 9.79755C-20.7839 26.3515 -0.542893 48.6113 20.8888 70.0667C32.7039 81.9964 45.0884 93.4546 59.4714 103.076C63.2379 105.59 67.1339 107.951 70.9937 110.115C92.9522 122.275 116.699 128.73 140.763 130.334C165.935 132.11 191.17 129.654 216.602 127.126C229.376 125.815 241.952 124.576 254.316 123.468C298.075 119.436 345.268 118.829 389.751 128.054C411.438 132.58 431.966 138.793 451.063 148.371C474.233 159.983 492.795 175.229 513.278 187.93C517.775 190.702 522.385 193.381 527.222 195.87C544.462 204.761 564.157 211.437 583.952 210.079C610.978 208.231 631.666 192.551 654.689 182.751C657.785 181.459 660.947 180.248 664.177 179.118C706.828 164.602 759.759 168.101 807.425 187.682C812.138 189.638 816.738 191.689 821.292 193.915C866.855 216.054 904.706 253.804 922.744 295.969" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-206.554 7.85568C-170.484 -12.9321 -119.44 -13.0026 -75.1787 5.03719C-65.8183 8.86943 -56.7428 13.4374 -48.2318 18.7909C-22.8489 34.7484 -1.95551 56.5593 19.7713 77.9071C31.7831 89.7651 44.2505 101.246 58.6491 110.809C62.4156 113.323 66.3116 115.684 70.2542 117.87C92.2955 130.054 116.156 136.414 140.2 137.762C165.299 139.144 190.364 136.33 215.729 133.722C228.518 132.352 241.162 131.194 253.593 130.165C297.491 126.609 344.736 127.141 389.271 136.504C411.025 141.111 431.698 147.113 450.786 156.064C473.961 166.99 492.669 181.026 513.085 192.646C517.561 195.164 522.135 197.645 526.952 199.879C544.047 207.981 563.566 213.985 583.243 212.408C610.264 210.245 631.22 194.888 654.565 185.548C657.743 184.279 660.89 183.126 664.172 182.135C707.246 168.357 760.074 172.579 807.874 192.322C812.588 194.278 817.188 196.329 821.741 198.555C867.47 220.739 905.616 258.381 924.182 300.564" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-207.884 20.1391C-172.616 -1.30244 -122.032 -2.30933 -77.8216 14.5921C-68.4817 18.1689 -59.2613 22.526 -50.6208 27.7269C-24.9012 43.0879 -3.35547 64.45 18.7492 85.7125C30.875 97.4764 43.5236 108.944 58.005 118.529C61.8543 121.066 65.6675 123.405 69.6257 125.533C91.7499 137.738 115.626 144.041 139.747 145.097C164.759 146.143 189.669 142.912 215.066 140.187C227.937 138.84 240.581 137.681 253.064 136.792C297.102 133.71 344.398 135.381 388.984 144.884C410.889 149.593 431.64 155.304 450.702 163.686C473.981 173.89 492.72 186.809 513.07 197.35C517.509 199.67 522.063 201.896 526.844 203.933C543.795 211.246 563.205 216.659 582.763 214.862C609.696 212.363 631.072 197.431 654.737 188.552C657.899 187.341 661.197 186.291 664.478 185.3C707.879 172.297 760.768 177.286 808.553 197.087C813.267 199.043 817.867 201.094 822.503 203.342C868.496 225.536 906.855 263.049 925.85 305.286" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-209.211 32.4214C-174.677 10.4065 -124.688 8.30203 -80.3941 24.2263C-70.992 27.5699 -61.7249 31.7522 -52.9238 36.684C-26.7846 51.4708 -4.66934 72.3616 17.8133 93.5388C30.1357 105.231 42.7844 116.698 57.447 126.271C61.2963 128.807 65.1923 131.168 69.1504 133.296C91.4403 145.547 115.332 151.791 139.464 152.476C164.471 153.208 189.159 149.479 214.555 146.754C227.442 145.348 240.153 144.27 252.688 143.52C296.881 140.855 344.115 143.759 388.851 153.365C410.906 158.177 431.652 163.575 450.771 171.409C474.056 180.928 492.941 192.637 513.141 202.075C517.56 204.139 522.092 206.109 526.837 207.949C543.576 214.393 562.863 219.273 582.4 217.22C609.329 214.407 630.958 199.855 654.959 191.38C658.189 190.249 661.486 189.2 664.835 188.289C708.644 176.083 761.445 181.735 809.365 201.698C814.079 203.655 818.762 205.728 823.315 207.954C869.474 230.193 908.128 267.598 927.651 309.853" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-210.556 44.7635C-176.741 22.1171 -127.294 19.0539 -82.9691 33.862C-73.5204 37.0308 -64.1755 40.9216 -55.3277 45.6785C-28.8518 59.8688 -6.08416 80.3107 16.6936 101.38C29.2283 112.942 41.9597 124.432 56.6224 134.005C60.4718 136.541 64.3833 138.844 68.3259 141.031C90.7142 153.245 114.689 159.512 138.816 159.883C163.75 160.22 188.268 156.133 213.695 153.291C226.598 151.828 239.376 150.83 251.963 150.219C296.296 148.029 343.581 152.071 388.369 161.816C410.59 166.674 431.382 171.896 450.491 179.103C473.88 187.9 492.713 198.47 512.945 206.792C517.343 208.601 521.84 210.374 526.564 211.958C543.159 217.614 562.254 221.879 581.673 219.607C608.514 216.458 630.561 202.331 654.817 194.236C658.114 193.186 661.395 192.195 664.827 191.306C709.06 179.839 761.742 186.272 809.729 206.316C814.443 208.273 819.11 210.404 823.762 212.594C870.103 234.82 909.036 272.176 928.989 314.485" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-211.884 57.0472C-178.871 33.7469 -129.952 29.6666 -85.6104 43.4168C-76.0994 46.3524 -66.7078 50.0683 -57.715 54.6144C-30.9024 68.2081 -7.48241 88.201 15.6732 109.185C28.4047 120.676 41.2345 132.13 55.9801 141.725C59.8294 144.261 63.741 146.564 67.7664 148.773C90.2375 161.01 114.31 167.241 138.35 167.275C163.197 167.277 187.559 162.773 212.919 159.851C225.92 158.351 238.683 157.412 251.322 156.939C295.81 155.166 343.131 160.406 387.97 170.29C410.341 175.25 431.211 180.181 450.295 186.819C473.689 194.93 492.668 204.289 512.735 211.567C517.113 213.12 521.573 214.696 526.178 216.061C542.629 220.927 561.631 224.543 580.931 222.051C607.767 218.588 630.151 204.864 654.644 197.208C657.941 196.158 661.29 195.247 664.69 194.475C709.332 183.806 762.009 190.925 810.063 211.05C814.777 213.006 819.444 215.138 824.096 217.328C870.685 239.621 909.846 276.789 930.229 319.152" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-213.214 69.3312C-180.919 45.3992 -132.543 40.3601 -88.1856 53.0524C-78.6279 55.8131 -69.1741 59.2958 -60.0363 63.6311C-32.8041 76.6504 -8.81452 96.1721 14.6362 117.049C27.4816 128.445 40.4771 139.944 55.321 149.503C59.1859 151.981 63.1647 154.365 67.1074 156.552C89.6614 168.811 113.833 175.007 137.883 174.669C162.74 174.298 186.865 169.355 212.241 166.374C225.257 164.816 238.17 163.98 250.778 163.624C295.405 162.325 342.778 168.704 387.685 178.669C410.206 183.732 431.154 188.372 450.227 194.382C473.643 201.749 492.669 209.934 512.752 216.153C517.093 217.51 521.549 218.772 526.118 219.939C542.424 224.017 561.235 227.019 580.499 224.33C607.329 220.553 629.982 207.151 654.796 199.956C658.161 198.986 661.577 198.156 664.977 197.384C710.028 187.512 762.601 195.353 810.805 215.581C815.519 217.538 820.269 219.692 824.921 221.882C871.691 244.161 911.049 281.259 932.043 323.662" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-214.455 81.638C-182.88 57.0744 -135.331 51.8123 -90.6569 62.6524C-80.9903 65.005 -71.5209 68.4295 -62.3364 72.5898C-34.7675 85.0126 -10.1256 104.085 13.7031 124.877C26.7452 136.201 39.8391 147.664 54.6675 157.282C58.5324 159.76 62.5112 162.144 66.5367 164.353C89.1736 176.635 113.443 182.794 137.488 182.143C162.258 181.436 186.228 176.076 211.619 173.037C224.636 171.478 237.632 170.664 250.291 170.447C295.058 169.624 342.482 177.142 387.441 187.246C410.112 192.412 431.039 196.797 450.269 202.224C473.772 208.927 492.845 215.937 512.877 221.018C517.198 222.118 521.617 223.183 526.166 224.095C542.245 227.362 560.947 229.772 580.108 226.805C606.851 222.692 629.923 209.715 654.99 202.9C658.339 201.989 661.838 201.181 665.306 200.49C710.781 191.357 763.25 199.92 811.506 220.288C816.288 222.325 820.97 224.398 825.622 226.588C872.574 248.855 912.227 285.845 933.65 328.302" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-215.804 93.9809C-185.031 68.7634 -137.709 61.6899 -93.2365 72.2885C-83.5544 74.5827 -73.9916 77.6574 -64.6621 81.6069C-36.7564 93.433 -11.4621 112.057 12.7445 132.763C25.885 144.051 39.16 155.501 54.0868 165.083C58.0345 167.583 61.9306 169.945 65.9716 172.095C88.7741 184.423 113.06 190.524 137.115 189.5C161.797 188.457 185.529 182.658 211.034 179.524C224.067 177.907 237.13 177.174 249.84 177.096C294.763 176.689 342.14 185.382 387.234 195.648C410.055 200.917 431.06 205.01 450.264 209.868C473.788 215.826 492.909 221.662 512.858 225.72C517.158 226.566 521.542 227.433 526.054 228.148C541.988 230.625 560.598 232.386 579.64 229.199C606.378 224.772 629.703 212.175 655.091 205.822C658.508 204.992 661.991 204.242 665.526 203.631C711.409 195.295 763.858 204.603 812.182 225.052C816.963 227.09 821.646 229.163 826.282 231.411C873.482 253.745 913.347 290.605 935.298 333.081" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-217.13 106.265C-187.091 80.4741 -140.364 72.3028 -95.8906 81.9016C-86.1618 84.0209 -76.5212 86.8041 -67.0623 90.6009C-38.8198 101.83 -12.8732 120.005 11.6441 140.545C24.9813 151.762 38.3548 163.176 53.3644 172.78C57.3122 175.281 61.291 177.664 65.3321 179.815C88.2175 192.165 112.586 198.288 136.652 196.892C161.344 195.477 184.838 189.239 210.344 186.105C223.475 184.452 236.522 183.777 249.3 183.78C294.362 183.848 341.791 193.68 386.936 204.085C409.908 209.457 430.99 213.259 450.169 217.547C473.797 222.784 492.949 227.503 512.847 230.422C517.112 231.07 521.474 231.682 525.966 232.142C541.771 233.772 560.174 234.976 579.18 231.592C605.83 226.829 629.575 214.658 655.299 208.707C658.798 207.899 662.266 207.208 665.869 206.677C712.078 199.117 764.521 209.111 812.98 229.721C817.761 231.759 822.526 233.855 827.163 236.103C874.544 258.423 914.705 295.177 937.085 337.706" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-218.461 118.553C-189.141 92.13 -142.941 82.9412 -98.4515 91.482C-88.6604 93.3681 -78.8904 95.9987 -69.3691 99.5625C-40.7071 110.218 -14.1907 127.921 10.7045 148.376C24.2386 159.521 37.6276 170.878 52.7201 180.504C56.6679 183.005 60.6468 185.389 64.6878 187.54C87.6562 199.913 112.04 205.978 136.101 204.268C160.706 202.516 184.045 195.861 209.499 192.588C222.646 190.877 235.76 190.282 248.59 190.424C293.808 190.909 341.272 201.939 386.47 212.483C409.591 217.959 430.653 221.504 449.904 225.187C473.539 229.738 492.738 233.282 512.668 235.085C516.911 235.478 521.253 235.834 525.627 236.074C541.287 236.915 559.499 237.506 578.401 233.844C605.062 228.708 629.045 216.975 655.09 211.486C658.574 210.736 662.192 210.148 665.794 209.617C712.428 202.796 764.752 213.571 813.278 234.263C818.059 236.3 822.809 238.454 827.461 240.644C875.008 263.01 915.464 299.657 938.274 342.241" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-219.811 130.901C-191.292 103.823 -145.619 93.6169 -101.031 101.122C-91.1775 102.775 -81.3607 105.231 -71.7772 108.561C-42.7783 118.62 -15.6094 135.875 9.58104 156.223C23.2291 167.274 36.7838 178.675 51.9748 188.267C55.9226 190.768 59.917 193.093 64.0254 195.325C87.0922 207.662 111.559 213.75 135.631 211.667C160.148 209.58 183.332 202.507 208.802 199.176C221.964 197.406 235.146 196.892 248.028 197.173C293.385 198.134 340.901 210.303 386.166 220.928C409.454 226.448 430.578 229.761 449.804 232.875C473.542 236.703 492.789 239.073 512.554 239.831C516.777 239.968 521.083 240.127 525.435 240.112C540.868 240.141 558.988 240.082 577.838 236.281C604.494 230.831 628.829 219.442 655.113 214.392C658.663 213.723 662.281 213.134 665.951 212.684C712.993 206.66 765.297 218.18 813.906 238.895C818.687 240.933 823.437 243.087 828.172 245.3C875.968 267.734 916.704 304.332 940.042 346.936" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-221.141 143.191C-193.342 115.481 -148.196 104.257 -103.689 110.74C-93.8566 112.137 -83.8947 114.382 -74.1661 117.502C-44.8303 126.964 -17.009 143.77 8.5595 164.033C22.4044 175.013 36.0576 186.379 51.3315 195.993C55.2793 198.494 59.3566 200.842 63.465 203.074C86.6976 215.456 111.18 221.486 135.247 219.089C159.76 216.688 182.722 209.117 208.192 205.786C221.437 204.038 234.619 203.525 247.552 203.944C293.049 205.38 340.617 218.689 385.934 229.453C409.371 235.077 430.574 238.098 449.79 240.584C473.549 243.668 492.827 244.921 512.624 244.563C516.811 244.502 521.096 244.406 525.413 244.193C540.702 243.434 558.713 242.782 577.377 238.681C603.946 232.894 628.7 221.931 655.237 217.261C658.854 216.672 662.457 216.142 666.111 215.751C713.578 210.465 765.778 222.708 814.439 243.563C819.22 245.601 824.053 247.778 828.705 249.968C876.698 272.331 917.646 308.801 941.414 351.459" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-222.479 155.482C-195.399 127.139 -150.863 114.875 -106.258 120.322C-96.378 121.545 -86.3539 123.556 -76.4802 126.465C-46.7247 135.353 -18.3336 151.688 7.61291 171.866C21.6546 182.774 35.3906 194.163 50.6802 203.719C54.628 206.22 58.7054 208.568 62.8138 210.8C86.1294 223.205 110.711 229.199 134.773 226.489C159.213 223.692 182.004 215.763 207.506 212.315C220.766 210.509 234.016 210.076 247.001 210.634C292.654 212.487 340.174 226.972 385.626 237.898C409.214 243.625 430.495 246.354 449.685 248.271C473.45 250.669 492.858 250.769 512.521 249.249C516.687 248.933 520.936 248.64 525.232 248.171C540.377 246.623 558.197 245.357 576.825 241.058C603.389 234.957 628.396 224.374 655.254 220.166C658.872 219.577 662.542 219.127 666.263 218.816C714.056 214.306 766.235 227.294 815.047 248.253C819.911 250.313 824.661 252.468 829.396 254.68C877.555 277.089 918.799 313.452 943.095 356.13" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-223.828 167.832C-197.55 138.834 -153.443 125.516 -108.836 129.963C-98.8945 130.952 -88.808 132.731 -78.8876 135.465C-48.7952 143.756 -19.7672 159.701 6.49014 179.713C20.6458 190.528 34.5632 201.903 49.9201 211.54C53.9508 214.064 57.9453 216.39 62.1521 218.585C85.5506 231.013 110.23 236.972 134.303 233.889C158.656 230.756 181.292 222.41 206.727 218.88C220.003 217.016 233.32 216.664 246.357 217.361C292.149 219.689 339.722 235.313 385.226 246.379C408.963 252.209 430.239 254.625 449.569 256.017C473.439 257.693 492.796 256.653 512.474 254.075C516.62 253.503 520.849 252.954 525.109 252.288C540.109 249.95 557.753 248.012 576.346 243.516C602.822 237.079 628.248 226.92 655.36 223.093C659.045 222.585 662.782 222.215 666.503 221.905C714.721 218.134 766.879 231.867 815.743 252.966C820.592 255.084 825.358 257.18 830.092 259.393C878.433 281.789 919.972 318.046 944.698 360.778" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-225.081 180.145C-199.521 150.515 -156.024 136.157 -111.417 139.604C-101.413 140.36 -91.2794 141.963 -81.2139 144.486C-50.7847 152.18 -21.1041 167.676 5.53132 187.604C19.8838 198.347 33.8996 209.686 49.3394 219.346C53.3701 221.87 57.4475 224.218 61.5715 226.392C85.053 238.843 109.831 244.765 133.899 241.368C158.247 237.921 180.661 229.077 206.111 225.489C219.486 223.589 232.788 223.295 245.959 224.153C291.99 226.921 339.515 243.721 385.072 254.926C408.96 260.859 430.298 263.042 449.618 263.806C473.508 264.737 492.98 262.603 512.607 258.886C516.717 258.117 520.925 257.312 525.149 256.448C539.922 253.299 557.54 250.791 575.932 246.053C602.403 239.301 628.083 229.523 655.531 226.099C659.283 225.671 663.02 225.302 666.809 225.072C715.435 222.099 767.491 236.555 816.422 257.735C821.271 259.853 826.036 261.95 830.854 264.185C879.443 286.648 921.195 322.776 946.35 365.564" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-226.408 192.437C-201.582 162.233 -158.597 146.798 -113.974 149.187C-103.907 149.709 -93.7117 151.079 -83.5167 153.45C-52.6677 160.57 -22.4173 175.594 4.5961 195.437C19.0626 206.086 33.1613 217.448 48.6996 227.073C52.7303 229.596 56.8077 231.945 61.0146 234.141C84.5791 246.614 109.456 252.501 133.452 248.709C157.727 244.868 179.971 235.664 205.437 232.018C218.827 230.06 232.196 229.846 245.419 230.843C291.59 234.086 339.167 252.026 384.791 263.313C408.746 269.327 430.245 271.24 449.54 271.434C473.534 271.643 493.038 268.392 512.516 263.571C516.605 262.547 520.777 261.544 524.897 260.403C539.525 256.464 556.953 253.342 575.324 248.348C601.723 241.202 627.807 231.907 655.576 228.944C659.313 228.575 663.117 228.286 666.973 228.137C716.023 225.903 767.975 241.082 817.042 262.424C821.89 264.543 826.723 266.72 831.474 268.874C880.244 291.325 922.291 327.346 947.959 370.211" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-227.76 204.787C-203.736 173.927 -161.262 157.416 -116.555 158.827C-106.442 159.174 -96.1844 160.311 -85.9271 162.449C-54.7412 168.972 -23.9211 183.525 3.4704 203.283C18.1337 213.86 32.3309 225.187 47.9521 234.834C51.9828 237.357 56.0603 239.706 60.2672 241.902C83.9974 254.421 108.89 260.249 132.881 256.143C157.152 251.988 179.158 242.345 204.655 238.582C218.061 236.565 231.497 236.432 244.788 237.51C291.098 241.228 338.727 260.308 384.403 271.734C408.508 277.851 430.085 279.473 449.37 279.039C473.37 278.562 493.003 274.158 512.43 268.197C516.483 266.975 520.635 265.717 524.735 264.32C539.218 259.592 556.471 255.797 574.79 250.664C601.184 243.203 627.537 234.231 655.627 231.729C659.447 231.382 663.235 231.152 667.158 231.083C716.551 229.566 768.549 245.571 817.699 266.937C822.547 269.055 827.38 271.232 832.214 273.409C881.166 295.847 923.492 331.82 949.606 374.682" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-229.093 217.08C-205.788 185.588 -163.841 168.059 -119.134 168.469C-108.958 168.583 -98.6381 169.487 -88.2513 171.471C-56.713 177.339 -25.2559 191.502 2.51369 211.174C17.291 221.658 31.6539 233.03 47.3735 242.641C51.4199 245.106 55.5646 247.536 59.7715 249.732C83.6003 262.215 108.56 268.125 132.562 263.646C156.745 259.154 178.529 249.013 204.026 245.25C217.515 243.255 230.951 243.122 244.294 244.339C290.76 248.475 338.342 268.731 384.152 280.319C408.424 286.48 429.98 287.846 449.322 286.865C473.343 285.643 493.009 280.122 512.467 273.044C516.5 271.567 520.631 270.053 524.679 268.517C539.018 262.999 556.079 258.59 574.295 253.178C600.601 245.381 627.374 236.834 655.717 234.713C659.521 234.424 663.46 234.297 667.301 234.206C717.102 233.487 769.012 250.156 818.214 271.662C823.063 273.78 827.896 275.957 832.729 278.135C881.93 300.64 924.485 336.426 951.111 379.364" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-230.424 229.372C-207.839 197.247 -166.486 178.619 -121.778 178.029C-111.54 177.91 -101.091 178.661 -90.6414 180.412C-58.6833 185.705 -26.672 199.455 1.40834 218.962C16.3825 229.373 30.8439 240.709 46.5636 250.321C50.61 252.787 54.7703 255.158 58.9617 257.412C82.8734 269.918 107.932 275.792 131.944 270.941C156.123 266.135 177.669 255.554 203.197 251.674C216.702 249.621 230.205 249.568 243.6 250.925C290.206 255.536 337.839 276.932 383.702 288.659C408.123 294.924 429.758 295.998 449.074 294.448C473.199 292.503 492.896 285.865 512.205 277.683C516.217 275.95 520.312 274.239 524.34 272.447C538.534 266.14 555.42 261.057 573.584 255.507C599.886 247.395 626.995 239.251 655.576 237.569C659.448 237.361 663.387 237.234 667.294 237.223C717.52 237.244 769.311 254.695 818.663 276.304C823.512 278.423 828.428 280.622 833.246 282.858C882.628 305.351 925.463 341.088 952.534 384.023" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-231.776 241.724C-209.993 208.944 -169.152 189.239 -124.375 187.73C-114.075 187.377 -103.564 187.894 -93.0521 189.412C-60.7571 194.109 -28.0932 207.409 0.380807 226.772C15.5518 237.112 30.1115 248.413 45.9142 258.047C49.9607 260.513 54.121 262.884 58.4108 265.103C82.4055 277.631 107.563 283.469 131.57 278.304C155.676 273.103 177.052 262.163 202.596 258.225C216.116 256.113 229.687 256.141 243.133 257.637C289.895 262.665 337.564 285.26 383.479 297.126C408.051 303.494 429.763 304.277 449.054 302.156C473.201 299.467 493.012 291.733 512.27 282.412C516.246 280.481 520.32 278.515 524.327 276.467C538.377 269.37 555.154 263.696 573.215 257.867C599.429 249.419 626.875 241.677 655.793 240.399C659.747 240.213 663.655 240.202 667.63 240.272C718.197 241.01 770.051 259.229 819.455 280.978C824.386 283.119 829.22 285.296 834.037 287.532C883.586 310.07 926.716 345.701 954.217 388.69" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-233.109 254.016C-212.044 220.604 -171.731 199.881 -126.938 197.312C-116.659 196.704 -106.017 197.069 -95.3607 198.376C-62.7288 202.475 -29.4123 215.326 -0.560242 234.604C14.7247 244.85 29.4502 256.196 45.3358 265.853C49.4651 268.341 53.6254 270.712 57.8324 272.909C81.9102 285.46 107.166 291.262 131.086 285.76C155.104 280.222 176.34 268.807 201.885 264.869C215.503 262.721 229.059 262.807 242.557 264.442C289.458 269.945 337.179 293.681 383.146 305.687C407.868 312.158 429.56 312.685 449.007 309.981C473.159 306.605 493.1 297.718 512.224 287.234C516.179 285.048 520.135 282.861 524.106 280.616C537.928 272.708 554.597 266.441 572.54 260.392C598.764 251.571 626.531 244.291 655.702 243.393C659.641 243.266 663.632 243.278 667.674 243.429C718.65 244.964 770.4 263.906 819.872 285.737C824.803 287.878 829.637 290.055 834.537 292.313C884.35 314.861 927.693 350.362 955.706 393.429" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-234.441 266.309C-214.096 232.265 -174.377 210.443 -129.583 206.873C-119.241 206.032 -108.538 206.163 -97.7517 207.317C-64.7828 210.819 -30.8137 223.221 -1.66635 242.392C13.7999 252.624 28.5566 263.854 44.5252 273.533C48.6544 276.021 52.8148 278.393 57.1047 280.612C81.3482 293.208 106.604 299.01 130.55 293.078C154.564 287.225 175.562 275.371 201.055 271.293C214.689 269.087 228.312 269.254 241.862 271.027C288.903 277.006 336.676 301.882 382.71 313.969C407.583 320.544 429.352 320.779 448.774 317.505C473.03 313.407 493.003 303.402 512.06 291.837C515.979 289.453 519.914 287.011 523.864 284.51C537.543 275.811 554.036 268.872 571.942 262.625C598.079 253.468 626.198 246.533 655.675 246.155C659.681 246.108 663.738 246.2 667.781 246.351C719.181 248.626 770.828 268.292 820.435 290.284C825.351 292.484 830.267 294.683 835.1 296.861C885.095 319.396 928.717 354.848 957.176 397.911" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-235.703 278.684C-216.175 244.043 -176.952 221.086 -132.091 216.597C-121.687 215.521 -110.936 215.478 -100.005 216.421C-66.6009 219.29 -32.0775 231.278 -2.55207 250.364C13.0282 260.502 27.9662 271.718 44.0177 281.421C48.147 283.909 52.3074 286.28 56.5972 288.499C80.9237 301.118 106.278 306.885 130.219 300.638C154.228 294.471 174.989 282.177 200.498 278.04C214.132 275.834 227.838 276.024 241.439 277.936C288.636 284.333 336.362 310.385 382.531 322.634C407.554 329.311 429.334 329.174 448.813 325.352C473.105 320.451 493.193 309.351 512.183 296.704C516.082 294.064 520.079 291.389 523.91 288.668C537.527 279.202 553.747 271.626 571.534 265.159C597.583 255.665 626.121 249.155 655.934 249.181C660.008 249.215 664.066 249.307 668.175 249.538C719.933 252.473 771.611 273.023 821.218 295.016C826.134 297.215 831.05 299.415 835.884 301.593C886.044 324.173 929.977 359.46 958.949 402.6" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-237.041 290.979C-218.232 255.704 -179.604 231.648 -134.658 226.18C-124.208 224.93 -113.379 224.595 -102.401 225.363C-68.6448 227.576 -33.5 239.232 -3.58081 258.174C12.1963 268.241 27.2172 279.479 43.3671 289.146C47.4965 291.634 51.7397 294.028 56.0296 296.247C80.4391 308.889 105.892 314.62 129.844 308C153.765 301.497 174.386 288.726 199.911 284.532C213.561 282.267 227.318 282.596 241.003 284.531C288.339 291.403 336.117 318.595 382.338 330.983C407.511 337.764 429.354 337.394 448.822 332.944C473.121 327.355 493.338 315.102 512.195 301.293C516.073 298.397 520.034 295.524 523.845 292.548C537.234 282.27 553.361 274.042 571.112 267.379C597.09 257.489 625.948 251.441 656.098 251.869C660.172 251.903 664.281 252.135 668.458 252.447C720.625 256.179 772.215 277.394 821.956 299.549C826.873 301.749 831.789 303.949 836.705 306.149C887.047 328.716 931.26 363.955 960.677 407.091" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-238.376 303.272C-220.368 267.343 -182.267 242.269 -137.223 235.764C-126.71 234.28 -115.835 233.77 -104.712 234.327C-70.6181 235.943 -34.8206 247.149 -4.60621 265.984C11.3677 275.979 26.4871 287.182 42.6372 296.849C46.7665 299.337 51.0098 301.731 55.2997 303.95C79.8077 316.556 105.344 322.309 129.291 315.375C153.14 308.477 173.591 295.348 199.131 291.095C212.879 288.794 226.621 289.181 240.358 291.255C287.849 298.544 335.648 326.994 382.019 339.485C407.358 346.311 429.263 345.707 448.706 340.687C473.026 334.354 493.358 321.004 512.148 306.114C516.073 303.043 519.847 299.869 523.705 296.718C536.95 285.65 552.885 276.808 570.601 269.947C596.573 259.743 625.768 254.098 656.156 254.966C660.297 255.08 664.406 255.312 668.568 255.682C721.159 260.155 772.63 282.151 822.522 304.41C827.438 306.61 832.354 308.81 837.271 311.01C887.861 333.644 932.303 368.695 962.232 411.909" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-239.722 315.625C-222.434 279.063 -184.995 252.808 -139.898 245.442C-129.405 243.702 -118.385 242.981 -107.133 243.385C-72.702 244.404 -36.2519 255.161 -5.65934 273.91C10.4286 283.811 25.7137 295.059 41.9623 304.69C46.0916 307.178 50.3349 309.572 54.7233 311.755C79.3971 324.407 104.866 330.079 128.808 322.831C152.652 315.619 172.865 302.049 198.354 297.657C212.118 295.298 225.928 295.765 239.716 297.979C287.347 305.743 335.197 335.334 381.621 347.965C407.11 354.894 429.093 353.998 448.51 348.408C472.934 341.352 493.298 326.885 511.954 310.832C515.858 307.505 519.597 304.134 523.434 300.727C536.534 288.869 552.361 279.435 569.891 272.273C595.858 261.755 625.39 256.512 656.031 257.761C660.239 257.957 664.416 258.269 668.578 258.639C721.578 263.91 773.044 286.593 822.905 308.97C827.821 311.17 832.82 313.392 837.737 315.592C888.509 338.214 933.23 373.216 963.589 416.484" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-241.057 327.92C-224.488 290.725 -187.576 263.452 -142.462 255.026C-99.4044 246.985 -47.7464 256.961 -6.66946 281.662C13.7448 293.98 32.7605 308.547 54.0759 319.481C78.8327 332.155 104.4 337.792 128.353 330.171C152.109 322.622 172.183 308.577 197.672 304.185C259.158 293.602 321.359 339.944 381.317 356.409C445.746 374.079 487.429 339.896 523.34 304.722C558.035 270.784 612.302 255.317 668.832 261.664C727.511 268.276 783.773 295.943 838.447 320.242C889.386 342.909 934.319 377.782 965.207 421.068" stroke="#00C8CB" strokeMiterlimit="10" />
  </svg>
);

const backgroundMobile = (
  <svg width="375" height="410" viewBox="0 0 375 410" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-318.001 -34.2461C-283.319 -49.6035 -233.786 -41.2273 -200.126 -14.368C-173.534 6.84846 -157.437 37.1811 -129.106 56.4326C-87.7588 84.4599 -34.8248 80.3237 11.1092 74.1231C57.0432 67.9224 109.257 62.6463 151.617 89.2787C168.215 99.7395 181.345 114.088 196.379 126.336C211.402 138.626 230.216 149.413 248.745 149.038C268.307 148.626 282.255 136.159 298.078 127.936C328.342 112.11 369.967 111.509 406.633 126.38C443.3 141.251 474.145 171.228 487.009 204.566" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-318.962 -25.3909C-290.818 -38.5434 -252.277 -35.7393 -220.608 -19.6444C-214.016 -16.2926 -207.699 -12.3844 -201.79 -7.90983C-184.506 5.2592 -171.273 22.3397 -156.577 38.0116C-148.423 46.7855 -139.753 55.0671 -129.501 61.9724C-126.785 63.785 -124.036 65.4714 -121.252 67.0317C-105.657 75.7357 -88.6757 80.442 -71.275 82.3771C-53.0645 84.3951 -34.3527 83.5739 -16.107 81.8613C-6.9898 81.026 2.00443 79.9322 10.7971 78.8744C41.9671 75.008 76.0288 72.0552 107.922 78.4321C123.225 81.5149 137.941 86.5577 151.626 94.7475C168.239 104.672 181.463 118.19 196.437 129.701C199.735 132.211 203.115 134.653 206.73 136.933C219.464 145.092 234.03 151.176 248.491 150.697C268.05 150.059 282.24 137.882 298.245 129.934C300.381 128.886 302.575 127.855 304.856 126.981C334.667 114.774 373.049 115.777 407.215 129.706C410.566 131.058 413.883 132.537 417.095 134.168C449.51 150.103 476.226 177.572 488.244 207.844" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-319.929 -16.4917C-292.304 -30.0995 -254.214 -28.0026 -222.462 -12.6963C-215.825 -9.51262 -209.475 -5.73044 -203.521 -1.42393C-185.934 11.3311 -172.291 28.0719 -157.381 43.6663C-149.085 52.3884 -140.357 60.6864 -130.093 67.5498C-127.377 69.3624 -124.628 71.0488 -121.844 72.6092C-106.13 81.3456 -89.148 86.0519 -71.7397 87.7188C-53.5328 89.5105 -35.0522 88.3567 -16.7356 86.618C-7.60709 85.7406 1.43562 84.705 10.2656 83.7473C41.5475 80.1812 75.5754 78.0754 107.566 84.5688C122.988 87.6838 137.749 92.5585 151.415 100.338C168.104 109.742 181.361 122.413 196.228 133.129C199.5 135.497 202.865 137.755 206.454 139.893C219.083 147.483 233.512 153.124 247.887 152.488C267.382 151.607 281.875 139.736 298.063 132.062C300.258 131.031 302.49 130.1 304.711 129.21C334.817 117.577 373.124 119.102 407.398 133.104C410.749 134.457 414.054 135.978 417.338 137.583C449.872 153.55 476.812 180.9 489.2 211.227" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-320.826 -7.61962C-293.779 -21.6989 -256.08 -20.2931 -224.306 -5.79143C-217.635 -2.73383 -211.229 0.838202 -205.181 5.03475C-187.352 17.3598 -173.238 33.777 -158.056 49.3099C-149.618 57.9802 -140.819 66.2524 -130.495 73.132C-127.779 74.9447 -125.03 76.6312 -122.187 78.2076C-106.412 86.9603 -89.3598 91.6408 -72.0148 93.0653C-53.8712 94.6147 -35.5023 93.1604 -17.1632 91.3376C-7.96375 90.4342 1.06774 89.4406 9.93501 88.583C41.3773 85.3755 75.3826 84.0747 107.41 90.6683C122.941 93.8576 137.747 98.5642 151.406 105.891C168.098 114.801 181.39 126.625 196.268 136.579C199.525 138.763 202.864 140.878 206.379 142.816C218.903 149.837 233.205 154.994 247.555 154.215C267.058 153.066 281.722 141.512 298.141 134.17C300.325 133.181 302.617 132.266 304.886 131.434C335.298 120.334 373.53 122.379 407.853 136.44C411.193 137.835 414.569 139.33 417.793 140.918C450.518 156.893 477.599 184.191 490.297 214.557" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-321.782 1.23745C-295.253 -13.2972 -258.006 -12.5987 -226.16 1.1564C-219.444 4.04589 -213.004 7.49188 -206.852 11.5364C-188.791 23.4734 -174.196 39.5249 -158.741 54.9964C-150.221 63.5989 -141.291 71.8613 -130.919 78.7993C-128.203 80.6119 -125.394 82.3146 -122.611 83.875C-106.777 92.6439 -89.653 97.2986 -72.3004 98.4548C-54.1491 99.7359 -35.9517 97.965 -17.6126 96.1421C-8.40189 95.1967 0.666921 94.3032 9.5827 93.5037C41.1369 90.5966 75.168 90.1589 107.244 96.8108C122.883 100.074 137.685 104.555 151.457 111.462C168.164 119.835 181.55 130.829 196.32 139.987C199.562 141.986 202.886 143.918 206.375 145.713C218.807 152.124 233.008 156.939 247.283 155.959C266.771 154.626 281.689 143.32 298.291 136.253C300.523 135.321 302.804 134.448 305.133 133.633C335.888 123.165 374.056 125.689 408.428 139.809C411.827 141.219 415.144 142.698 418.428 144.303C451.284 160.268 478.578 187.489 491.585 217.894" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-322.749 10.136C-296.739 -4.85401 -259.932 -4.90487 -228.015 8.1036C-221.265 10.867 -214.721 14.161 -208.583 18.0213C-190.28 29.5283 -175.214 45.2561 -159.547 60.65C-150.885 69.2008 -141.895 77.4795 -131.512 84.3755C-128.796 86.1882 -125.986 87.8909 -123.143 89.4674C-107.249 98.2526 -90.0438 102.839 -72.7059 103.811C-54.6067 104.808 -36.5323 102.779 -18.2415 100.898C-9.01963 99.9101 0.097708 99.0747 9.06201 98.3333C40.7169 95.7687 74.7851 96.1521 106.899 102.904C122.586 106.226 137.493 110.555 151.257 117.009C167.969 124.888 181.459 135.009 196.181 143.388C199.408 145.203 202.707 146.993 206.18 148.604C218.508 154.446 232.583 158.776 246.772 157.638C266.257 156.079 281.368 145.005 298.202 138.27C300.494 137.355 302.763 136.523 305.129 135.808C336.19 125.874 374.284 128.918 408.753 143.154C412.152 144.565 415.469 146.044 418.752 147.649C451.728 163.646 479.235 190.79 492.623 221.208" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-323.711 18.9934C-298.278 3.53193 -261.803 2.80586 -229.923 14.9935C-223.188 17.5727 -216.539 20.7146 -210.308 24.465C-191.762 35.5417 -176.225 50.9459 -160.286 66.2782C-151.542 74.7612 -142.421 83.0302 -131.978 89.9424C-129.203 91.7712 -126.453 93.4579 -123.599 94.9924C-107.645 103.794 -90.428 108.339 -73.034 109.1C-54.9981 109.855 -37.0355 107.525 -18.7222 105.56C-9.44061 104.588 -0.323249 103.753 8.67835 103.112C40.434 100.889 74.5392 102.094 106.69 108.947C122.486 112.343 137.449 116.461 151.194 122.505C167.981 129.863 181.494 139.179 196.168 146.78C199.369 148.453 202.653 150.058 206.1 151.527C218.324 156.8 232.32 160.703 246.424 159.408C265.845 157.606 281.259 146.838 298.324 140.436C300.604 139.563 302.982 138.806 305.348 138.091C336.644 128.714 374.783 132.312 409.241 146.59C412.64 148.001 415.957 149.48 419.3 151.101C452.466 167.105 480.126 194.155 493.823 224.612" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-324.669 27.8501C-299.766 11.9752 -263.719 10.4577 -231.779 21.9406C-224.999 24.3517 -218.317 27.3676 -211.97 30.9239C-193.121 41.5866 -177.174 56.6509 -160.962 71.9218C-152.076 80.353 -142.955 88.6221 -132.382 95.5246C-129.606 97.3535 -126.797 99.0563 -123.943 100.591C-107.869 109.425 -90.6413 113.927 -73.2396 114.421C-55.2073 114.949 -37.405 112.26 -19.0917 110.295C-9.79878 109.282 -0.632906 108.504 8.406 107.963C40.2736 106.042 74.3337 108.135 106.593 115.062C122.497 118.532 137.456 122.425 151.243 128.074C168.034 134.938 181.652 143.381 196.218 150.187C199.404 151.676 202.673 153.096 206.094 154.423C218.165 159.07 232.072 162.588 246.161 161.108C265.579 159.08 281.175 148.587 298.483 142.475C300.812 141.66 303.189 140.903 305.604 140.246C337.195 131.444 375.27 135.52 409.825 149.915C413.224 151.326 416.6 152.821 419.884 154.426C453.169 170.463 481.042 197.436 495.121 227.906" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-325.638 36.7503C-301.254 20.42 -265.598 18.2111 -233.635 28.8892C-226.822 31.1742 -220.083 33.9798 -213.703 37.4101C-194.611 47.6426 -178.194 62.3833 -161.769 77.5765C-152.73 85.9139 -143.549 94.1993 -132.976 101.102C-130.2 102.931 -127.38 104.592 -124.537 106.168C-108.392 114.976 -91.1045 119.495 -73.7064 119.762C-55.7262 120.006 -38.0468 117.059 -19.711 115.01C-10.4069 113.954 -1.19247 113.235 7.88373 112.794C39.852 111.215 73.9492 114.13 106.246 121.157C122.269 124.659 137.262 128.425 151.042 133.622C167.908 139.966 181.488 147.588 196.078 153.589C199.249 154.893 202.492 156.172 205.898 157.314C217.865 161.392 231.634 164.468 245.637 162.83C264.992 160.559 280.89 150.372 298.381 144.535C300.758 143.778 303.125 143.063 305.599 142.422C337.496 134.153 375.485 138.792 410.088 153.246C413.487 154.656 416.853 156.193 420.207 157.773C453.623 173.799 481.698 200.737 496.086 231.246" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-326.596 45.6079C-302.79 28.8061 -267.515 25.8638 -235.54 35.779C-228.682 37.8959 -221.909 40.5754 -215.425 43.8536C-196.09 53.656 -179.202 68.0728 -162.505 83.2046C-153.324 91.4902 -144.072 99.7498 -133.44 106.669C-130.664 108.498 -127.843 110.158 -124.94 111.751C-108.736 120.575 -91.3776 125.069 -74.0428 125.093C-56.1258 125.094 -38.5582 121.847 -20.2709 119.739C-10.8958 118.658 -1.69258 117.98 7.42092 117.64C39.5011 116.361 73.6242 120.139 105.958 127.267C122.09 130.844 137.139 134.4 150.9 139.186C167.77 145.035 181.455 151.784 195.926 157.032C199.082 158.152 202.299 159.288 205.62 160.273C217.482 163.782 231.185 166.389 245.102 164.592C264.453 162.095 280.594 152.199 298.256 146.678C300.633 145.921 303.048 145.264 305.5 144.707C337.691 137.013 375.676 142.147 410.328 156.659C413.728 158.07 417.093 159.607 420.448 161.186C454.043 177.261 482.282 204.063 496.98 234.611" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-327.552 54.4659C-304.264 37.2087 -269.38 33.575 -237.394 42.7273C-230.502 44.7181 -223.685 47.2295 -217.096 50.3556C-197.459 59.7438 -180.16 73.8209 -163.249 88.875C-153.987 97.0928 -144.616 105.385 -133.912 112.278C-131.125 114.065 -128.256 115.784 -125.413 117.36C-109.149 126.201 -91.719 130.668 -74.3766 130.425C-56.452 130.157 -39.0559 126.593 -20.7572 124.443C-11.3709 123.32 -2.05947 122.717 7.03164 122.46C39.2125 121.524 73.3727 126.124 105.755 133.309C121.995 136.96 137.1 140.306 150.855 144.64C167.739 149.952 181.459 155.854 195.941 160.339C199.071 161.317 202.284 162.227 205.579 163.069C217.337 166.009 230.902 168.174 244.793 166.235C264.14 163.511 280.476 153.847 298.369 148.659C300.795 147.96 303.258 147.361 305.71 146.804C338.196 139.686 376.106 145.34 410.867 159.927C414.266 161.338 417.691 162.891 421.045 164.47C454.771 180.536 483.152 207.286 498.291 237.864" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-328.448 63.3405C-305.679 45.6278 -271.392 41.8332 -239.177 49.65C-232.207 51.3464 -225.378 53.8159 -218.755 56.8159C-198.876 65.7739 -181.106 79.5272 -163.924 94.5198C-154.519 102.686 -145.077 110.952 -134.384 117.887C-131.597 119.674 -128.728 121.393 -125.825 122.986C-109.502 131.843 -92.001 136.284 -74.6622 135.814C-56.8008 135.305 -39.5165 131.44 -21.2066 129.248C-11.8202 128.124 -2.44906 127.537 6.67936 127.381C38.961 126.787 73.1582 132.208 105.578 139.494C121.926 143.22 137.017 146.381 150.883 150.295C167.831 155.128 181.585 160.183 196.03 163.847C199.146 164.641 202.333 165.408 205.613 166.066C217.207 168.422 230.693 170.16 244.51 168.02C263.794 165.054 280.432 155.696 298.507 150.782C300.922 150.126 303.445 149.543 305.946 149.044C338.738 142.458 376.573 148.633 411.371 163.321C414.819 164.79 418.195 166.285 421.55 167.864C455.406 183.92 484 210.594 499.448 241.21" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-329.422 72.2407C-307.232 54.0564 -273.108 48.9558 -241.039 56.5983C-234.057 58.2527 -227.162 60.4699 -220.434 63.3178C-200.311 71.8456 -182.072 85.2751 -164.616 100.206C-155.141 108.346 -145.568 116.603 -134.804 123.512C-131.958 125.315 -129.148 127.018 -126.234 128.569C-109.792 137.458 -92.2793 141.858 -74.9329 141.119C-57.1348 140.367 -40.022 136.185 -21.6299 133.926C-12.2323 132.76 -2.81261 132.231 6.35311 132.175C38.7466 131.882 72.91 138.15 105.427 145.553C121.883 149.352 137.03 152.303 150.878 155.806C167.841 160.103 181.629 164.311 196.015 167.238C199.116 167.847 202.276 168.473 205.53 168.988C217.02 170.775 230.44 172.044 244.171 169.746C263.451 166.554 280.272 157.471 298.579 152.889C301.042 152.29 303.554 151.75 306.103 151.309C339.19 145.298 377.01 152.01 411.856 166.756C415.304 168.225 418.681 169.72 422.024 171.341C456.06 187.446 484.807 214.026 500.636 244.655" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-330.377 81.0988C-308.716 62.501 -275.022 56.6087 -242.952 63.5303C-235.937 65.0586 -228.985 67.0655 -222.164 69.8034C-201.798 77.9009 -183.088 91.0066 -165.409 105.818C-155.792 113.906 -146.148 122.137 -135.325 129.063C-132.478 130.866 -129.609 132.585 -126.695 134.136C-110.192 143.041 -92.6202 147.457 -75.2661 146.45C-57.4604 145.429 -40.519 140.931 -22.1268 138.671C-12.6583 137.479 -3.24981 136.993 5.96443 136.994C38.4587 137.044 72.6591 144.134 105.214 151.637C121.778 155.511 136.981 158.252 150.81 161.344C167.849 165.12 181.659 168.523 196.008 170.628C199.083 171.096 202.229 171.537 205.468 171.868C216.865 173.044 230.135 173.912 243.84 171.472C263.058 168.037 280.18 159.26 298.73 154.97C301.253 154.387 303.753 153.888 306.351 153.506C339.673 148.054 377.489 155.261 412.433 170.123C415.88 171.592 419.317 173.103 422.66 174.725C456.827 190.82 485.786 217.323 501.925 247.991" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-331.337 89.9595C-310.195 70.9058 -276.88 64.2798 -244.799 70.4385C-237.739 71.7986 -230.693 73.6956 -223.828 76.2654C-203.16 83.9488 -184.039 96.7148 -166.087 111.465C-156.327 119.502 -146.673 127.691 -135.789 134.632C-132.943 136.436 -130.073 138.155 -127.159 139.706C-110.597 148.628 -93.0138 153.001 -75.6632 151.768C-57.9208 150.505 -41.0911 145.706 -22.7362 143.346C-13.2564 142.112 -3.79935 141.683 5.45222 141.785C38.0585 142.135 72.2848 150.089 104.877 157.692C121.55 161.641 136.738 164.197 150.62 166.853C167.662 170.135 181.507 172.69 195.878 173.991C198.938 174.274 202.069 174.531 205.223 174.704C216.515 175.31 229.648 175.736 243.278 173.095C262.504 169.392 279.798 160.932 298.579 156.973C301.091 156.433 303.699 156.008 306.297 155.626C339.924 150.707 377.655 158.477 412.647 173.398C416.095 174.867 419.52 176.42 422.875 177.999C457.161 194.127 486.334 220.553 502.782 251.261" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-332.31 98.8641C-311.746 79.3383 -278.811 71.9785 -246.658 77.3904C-239.553 78.5823 -232.474 80.3531 -225.564 82.7548C-204.653 90.0082 -185.061 102.451 -166.896 117.124C-157.055 125.093 -147.281 133.314 -136.326 140.23C-133.48 142.034 -130.599 143.711 -127.637 145.32C-111.003 154.216 -93.3601 158.606 -76.0019 157.104C-58.3226 155.599 -41.6047 150.499 -23.2384 148.097C-13.7473 146.821 -4.24162 146.45 5.04729 146.652C37.7544 147.345 72.018 156.12 104.659 163.782C121.451 167.763 136.684 170.152 150.548 172.397C167.665 175.158 181.544 176.866 195.796 177.413C198.841 177.512 201.946 177.627 205.085 177.616C216.214 177.637 229.28 177.594 242.873 174.853C262.095 170.923 279.642 162.711 298.595 159.069C301.156 158.587 303.764 158.162 306.411 157.838C340.333 153.494 378.049 161.801 413.101 176.738C416.549 178.208 419.974 179.761 423.388 181.357C457.854 197.534 487.228 223.925 504.057 254.646" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-333.269 107.726C-313.223 87.7443 -280.668 79.6506 -248.575 84.3255C-241.484 85.3331 -234.301 86.9519 -227.286 89.2015C-206.132 96.0247 -186.07 108.144 -167.632 122.755C-157.649 130.673 -147.803 138.869 -136.789 145.801C-133.943 147.605 -131.003 149.298 -128.04 150.907C-111.287 159.836 -93.6326 164.184 -76.2778 162.456C-58.602 160.724 -42.0443 155.265 -23.6778 152.863C-14.127 151.603 -4.62127 151.232 4.70498 151.535C37.513 152.57 71.8138 162.167 104.492 169.93C121.393 173.985 136.682 176.163 150.538 177.956C167.671 180.18 181.572 181.083 195.848 180.825C198.867 180.781 201.957 180.712 205.07 180.558C216.094 180.011 229.082 179.541 242.541 176.584C261.7 172.411 279.55 164.505 298.685 161.138C301.294 160.713 303.892 160.331 306.527 160.049C340.755 156.237 378.397 165.066 413.486 180.104C416.934 181.574 420.419 183.143 423.773 184.723C458.381 200.848 487.909 227.147 505.048 257.908" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-334.236 116.589C-314.709 96.1513 -282.594 87.3076 -250.429 91.2355C-243.305 92.117 -236.076 93.5676 -228.957 95.6651C-207.5 102.074 -187.027 113.853 -168.317 128.404C-158.192 136.27 -148.287 144.482 -137.261 151.373C-134.415 153.176 -131.475 154.87 -128.512 156.479C-111.699 165.424 -93.9737 169.747 -76.6224 167.792C-58.9986 165.776 -42.5639 160.058 -24.1748 157.571C-14.6127 156.269 -5.05844 155.957 4.30516 156.359C37.2252 157.696 71.4923 168.14 104.268 176.019C121.277 180.149 136.622 182.117 150.46 183.499C167.597 185.229 181.592 185.301 195.771 184.205C198.775 183.977 201.839 183.765 204.937 183.427C215.858 182.311 228.708 181.398 242.141 178.298C261.296 173.899 279.329 166.267 298.696 163.232C301.305 162.808 303.951 162.484 306.634 162.26C341.098 159.007 378.724 168.373 413.922 183.486C417.43 184.972 420.855 186.525 424.269 188.121C458.996 204.28 488.737 230.501 506.257 261.276" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-335.209 125.494C-316.259 104.584 -284.454 94.9806 -252.288 98.1874C-245.119 98.9007 -237.846 100.183 -230.692 102.155C-208.993 108.133 -188.061 119.631 -169.127 134.062C-158.919 141.86 -148.883 150.063 -137.809 157.012C-134.903 158.832 -132.022 160.509 -128.989 162.093C-112.116 171.054 -94.3197 175.351 -76.9607 173.128C-59.4002 170.869 -43.077 164.85 -24.7364 162.305C-15.1629 160.961 -5.56012 160.707 3.84082 161.21C36.8617 162.889 71.166 174.155 103.979 182.135C121.096 186.339 136.438 188.081 150.377 189.084C167.589 190.293 181.547 189.543 195.738 187.684C198.727 187.272 201.777 186.876 204.848 186.396C215.665 184.71 228.388 183.312 241.795 180.07C260.887 175.428 279.222 168.103 298.772 165.343C301.43 164.976 304.124 164.71 306.808 164.486C341.577 161.767 379.189 171.67 414.424 186.884C417.921 188.412 421.357 189.923 424.772 191.519C459.63 207.669 489.583 233.813 507.413 264.628" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-336.11 134.374C-317.679 113.007 -286.313 102.654 -254.147 105.139C-246.933 105.684 -239.626 106.841 -232.368 108.66C-210.425 114.208 -189.023 125.382 -169.816 139.752C-159.466 147.499 -149.36 155.676 -138.226 162.642C-135.319 164.461 -132.379 166.155 -129.405 167.722C-112.473 176.7 -94.6054 180.971 -77.2499 178.521C-59.6929 176.036 -43.53 169.658 -25.178 167.071C-15.5336 165.701 -5.94191 165.489 3.55609 166.108C36.7488 168.104 71.0194 180.218 103.87 188.298C121.095 192.576 136.482 194.15 150.414 194.701C167.641 195.373 181.682 193.834 195.836 191.153C198.799 190.599 201.834 190.018 204.879 189.396C215.532 187.125 228.237 185.316 241.499 181.9C260.587 177.031 279.105 169.98 298.898 167.511C301.603 167.202 304.298 166.936 307.03 166.77C342.094 164.626 379.631 175.051 414.916 190.324C418.412 191.851 421.849 193.363 425.323 194.975C460.36 211.173 490.467 237.225 508.607 268.079" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-337.068 143.237C-319.166 121.457 -288.17 110.327 -255.992 112.049C-248.733 112.426 -241.381 113.414 -234.029 115.124C-211.784 120.258 -189.971 131.092 -170.491 145.401C-160.06 153.079 -149.893 161.273 -138.688 168.213C-135.782 170.033 -132.842 171.726 -129.808 173.31C-112.816 182.304 -94.8772 186.549 -77.5737 183.815C-60.0687 181.045 -44.0287 174.408 -25.6653 171.779C-16.0096 170.367 -6.36941 170.213 3.16594 170.932C36.4595 173.271 70.7673 186.207 103.666 194.346C120.941 198.682 136.443 200.062 150.357 200.202C167.659 200.353 181.723 198.009 195.769 194.532C198.717 193.793 201.726 193.071 204.697 192.248C215.245 189.407 227.812 187.156 241.059 183.555C260.096 178.401 278.905 171.699 298.929 169.562C301.624 169.296 304.367 169.088 307.147 168.98C342.518 167.369 379.98 178.315 415.362 193.705C418.858 195.233 422.343 196.802 425.768 198.356C460.937 214.545 491.257 240.52 509.766 271.43" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-338.044 152.143C-320.721 129.89 -290.093 117.984 -257.855 119.001C-250.562 119.252 -243.165 120.072 -235.769 121.613C-213.281 126.317 -191.056 136.811 -171.304 151.058C-160.731 158.685 -150.493 166.853 -139.229 173.809C-136.322 175.629 -133.382 177.323 -130.348 178.906C-113.236 187.934 -95.2865 192.137 -77.9865 189.176C-60.485 186.179 -44.6165 179.226 -26.2305 176.512C-16.5635 175.058 -6.87474 174.962 2.70917 175.739C36.1036 178.421 70.4486 192.179 103.385 200.418C120.768 204.829 136.327 205.999 150.233 205.686C167.539 205.342 181.697 202.166 195.706 197.868C198.628 196.987 201.622 196.08 204.578 195.072C215.022 191.663 227.463 188.926 240.673 185.225C259.706 179.845 278.709 173.375 298.964 171.571C301.719 171.321 304.451 171.155 307.28 171.105C342.897 170.011 380.392 181.552 415.834 196.959C419.33 198.487 422.816 200.057 426.301 201.626C461.6 217.806 492.122 243.746 510.952 274.654" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-339.004 161.008C-322.2 138.299 -291.952 125.658 -259.713 125.954C-252.376 126.037 -244.934 126.688 -237.444 128.119C-214.702 132.351 -192.018 142.563 -171.994 156.749C-161.338 164.308 -150.981 172.509 -139.645 179.439C-136.727 181.217 -133.739 182.969 -130.705 184.553C-113.522 193.554 -95.5236 197.816 -78.2159 194.586C-60.7776 191.347 -45.0692 184.034 -26.6832 181.321C-16.9564 179.882 -7.26754 179.786 2.35372 180.664C35.8602 183.646 70.1715 198.253 103.205 206.609C120.708 211.052 136.252 212.037 150.199 211.329C167.521 210.448 181.701 206.467 195.733 201.363C198.641 200.298 201.62 199.206 204.539 198.098C214.879 194.12 227.181 190.94 240.317 187.038C259.286 181.415 278.592 175.252 299.03 173.722C301.774 173.514 304.614 173.423 307.383 173.357C343.295 172.838 380.727 184.859 416.206 200.366C419.703 201.894 423.188 203.464 426.673 205.034C462.152 221.263 492.838 247.068 512.038 278.03" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-339.964 169.871C-323.678 146.706 -293.859 133.273 -261.619 132.848C-254.237 132.762 -246.702 133.303 -239.167 134.566C-216.122 138.383 -193.039 148.298 -172.79 162.364C-161.992 169.872 -151.564 178.046 -140.229 184.977C-137.311 186.755 -134.311 188.465 -131.289 190.091C-114.046 199.109 -95.9761 203.344 -78.6608 199.846C-61.226 196.38 -45.6891 188.751 -27.2804 185.953C-17.5424 184.472 -7.80492 184.435 1.85369 185.413C35.461 188.738 69.8095 204.167 102.881 212.623C120.491 217.141 136.092 217.915 150.021 216.797C167.418 215.395 181.621 210.608 195.545 204.708C198.438 203.458 201.39 202.224 204.295 200.932C214.53 196.384 226.707 192.719 239.805 188.717C258.771 182.868 278.319 176.995 298.929 175.782C301.721 175.632 304.561 175.54 307.379 175.533C343.597 175.547 380.943 188.131 416.531 203.714C420.027 205.241 423.572 206.828 427.046 208.44C462.656 224.659 493.544 250.429 513.065 281.389" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-340.939 178.778C-325.231 155.14 -295.781 140.931 -263.492 139.843C-256.065 139.588 -248.485 139.962 -240.905 141.056C-217.617 144.443 -194.063 154.033 -173.531 167.996C-162.591 175.452 -152.092 183.601 -140.697 190.549C-137.779 192.327 -134.779 194.036 -131.685 195.636C-114.383 204.671 -96.2421 208.88 -78.9303 205.156C-61.5475 201.405 -46.1335 193.517 -27.7135 190.677C-17.9642 189.154 -8.17817 189.174 1.51778 190.252C35.2372 193.878 69.6118 210.171 102.721 218.728C120.439 223.32 136.096 223.885 150.007 222.356C167.419 220.416 181.705 214.84 195.592 208.118C198.459 206.726 201.396 205.308 204.286 203.831C214.417 198.714 226.516 194.622 239.539 190.419C258.442 184.327 278.233 178.744 299.086 177.822C301.937 177.688 304.755 177.681 307.621 177.731C344.085 178.263 381.477 191.401 417.102 207.084C420.658 208.628 424.143 210.198 427.617 211.81C463.347 228.062 494.448 253.755 514.279 284.755" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-341.9 187.642C-326.71 163.549 -297.64 148.606 -265.34 146.753C-257.928 146.315 -250.254 146.578 -242.57 147.52C-219.039 150.476 -195.014 159.743 -174.209 173.644C-163.187 181.032 -152.569 189.214 -141.114 196.178C-138.136 197.972 -135.136 199.682 -132.102 201.265C-114.74 210.316 -96.5283 214.5 -79.2796 210.533C-61.96 206.539 -46.6466 198.308 -28.2265 195.468C-18.4061 193.919 -8.63129 193.981 1.10201 195.16C34.9223 199.129 69.334 216.244 102.48 224.902C120.308 229.568 135.949 229.948 149.973 227.998C167.389 225.564 181.768 219.155 195.559 211.596C198.411 210.019 201.263 208.442 204.126 206.823C214.094 201.12 226.114 196.602 239.052 192.24C257.962 185.879 277.985 180.63 299.02 179.982C301.861 179.89 304.738 179.899 307.653 180.007C344.412 181.115 381.729 194.774 417.403 210.516C420.959 212.06 424.444 213.63 427.978 215.258C463.898 231.517 495.152 257.117 515.353 288.172" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-342.86 196.506C-328.189 171.957 -299.548 156.221 -267.247 153.647C-259.789 153.04 -252.071 153.135 -244.293 153.967C-220.52 156.493 -196.024 165.436 -175.006 179.26C-163.854 186.638 -153.213 194.736 -141.698 201.716C-138.72 203.51 -135.72 205.22 -132.627 206.82C-115.145 215.903 -96.9328 220.087 -79.6652 215.809C-62.3491 211.589 -47.2071 203.04 -28.8242 200.1C-18.9925 198.509 -9.16913 198.63 0.601529 199.908C34.5227 204.22 68.9716 222.158 102.167 230.874C120.102 235.615 135.8 235.784 149.805 233.423C167.296 230.468 181.699 223.254 195.441 214.914C198.267 213.195 201.104 211.434 203.953 209.631C213.816 203.358 225.71 198.354 238.622 193.85C257.469 187.246 277.745 182.245 299.001 181.973C301.89 181.939 304.816 182.006 307.731 182.115C344.796 183.755 382.038 197.936 417.809 213.794C421.354 215.381 424.899 216.967 428.385 218.537C464.435 234.787 495.891 260.352 516.413 291.404" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-343.77 205.43C-329.688 180.451 -301.405 163.896 -269.055 160.659C-261.552 159.884 -253.8 159.852 -245.918 160.532C-221.83 162.601 -196.936 171.246 -175.645 185.009C-164.41 192.319 -153.638 200.407 -142.063 207.403C-139.086 209.198 -136.086 210.908 -132.992 212.508C-115.451 221.607 -97.1676 225.766 -79.9035 221.261C-62.591 216.814 -47.6204 207.949 -29.226 204.966C-19.3943 203.375 -9.51116 203.512 0.296852 204.891C34.3301 209.503 68.7454 228.289 102.038 237.122C120.082 241.937 135.787 241.838 149.833 239.083C167.351 235.548 181.836 227.544 195.53 218.424C198.341 216.521 201.224 214.591 203.986 212.63C213.805 205.804 225.501 200.34 238.328 195.678C257.112 188.831 277.69 184.137 299.189 184.155C302.126 184.18 305.052 184.246 308.016 184.413C345.338 186.529 382.603 201.348 418.374 217.207C421.919 218.793 425.464 220.379 428.95 221.95C465.12 238.232 496.8 263.678 517.692 294.786" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-344.734 214.295C-331.171 188.859 -303.316 171.512 -270.906 167.569C-263.37 166.668 -255.562 166.426 -247.646 166.98C-223.304 168.576 -197.961 176.981 -176.386 190.64C-165.009 197.899 -154.178 206.003 -142.532 212.974C-139.555 214.768 -136.495 216.494 -133.401 218.094C-115.8 227.211 -97.4456 231.343 -80.1738 226.569C-62.9245 221.88 -48.0544 212.671 -29.6487 209.647C-19.8058 208.013 -9.88524 208.25 -0.0174461 209.646C34.1167 214.601 68.5692 234.21 101.899 243.143C120.051 248.032 135.802 247.765 149.841 244.556C167.362 240.527 181.941 231.69 195.539 221.733C198.335 219.645 201.192 217.573 203.939 215.427C213.595 208.015 225.224 202.083 238.024 197.277C256.756 190.146 277.566 185.785 299.307 186.094C302.244 186.118 305.208 186.285 308.22 186.51C345.837 189.202 383.039 204.499 418.907 220.476C422.452 222.062 425.997 223.648 429.542 225.235C465.844 241.508 497.726 266.918 518.938 298.024" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-345.697 223.16C-332.712 197.252 -305.237 179.171 -272.756 174.48C-265.175 173.41 -257.333 173.042 -249.312 173.444C-224.727 174.609 -198.914 182.69 -177.126 196.272C-165.608 203.479 -154.705 211.557 -143.059 218.528C-140.082 220.323 -137.022 222.049 -133.928 223.649C-116.256 232.739 -97.8418 236.888 -80.5736 231.888C-63.3762 226.914 -48.6291 217.446 -30.2121 214.379C-20.2981 212.72 -10.3887 212.999 -0.483528 214.494C33.7627 219.751 68.2299 240.266 101.668 249.273C119.94 254.195 135.736 253.76 149.756 250.14C167.293 245.573 181.954 235.947 195.504 225.209C198.334 222.995 201.056 220.706 203.838 218.434C213.389 210.453 224.88 204.077 237.654 199.129C256.383 191.771 277.435 187.701 299.348 188.327C302.334 188.409 305.297 188.576 308.298 188.843C346.222 192.068 383.337 207.93 419.314 223.98C422.859 225.567 426.404 227.153 429.949 228.74C466.43 245.061 498.477 270.337 520.059 301.498" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-346.668 232.068C-334.201 205.703 -307.204 186.771 -274.685 181.459C-267.118 180.204 -259.172 179.684 -251.058 179.976C-226.23 180.71 -199.946 188.467 -177.885 201.987C-166.284 209.127 -155.262 217.238 -143.546 224.183C-140.568 225.977 -137.508 227.703 -134.344 229.278C-116.551 238.4 -98.1859 242.491 -80.9212 237.264C-63.7273 232.063 -49.1516 222.279 -30.7718 219.111C-20.8465 217.41 -10.8885 217.747 -0.946023 219.343C33.4011 224.942 67.9055 246.28 101.381 255.388C119.761 260.384 135.613 259.738 149.615 255.708C167.227 250.619 181.912 240.187 195.365 228.612C198.18 226.213 200.876 223.782 203.643 221.325C213.089 212.774 224.502 205.971 237.143 200.807C255.868 193.222 277.163 189.442 299.258 190.342C302.293 190.483 305.305 190.708 308.306 190.976C346.524 194.776 383.636 211.133 419.591 227.269C423.136 228.855 426.741 230.458 430.286 232.044C466.898 248.357 499.146 273.597 521.038 304.797" stroke="#00C8CB" strokeMiterlimit="10" />
    <path d="M-347.631 240.934C-335.683 214.113 -309.065 194.446 -276.534 188.37C-245.485 182.572 -208.235 189.765 -178.614 207.578C-163.893 216.46 -150.181 226.964 -134.811 234.849C-116.959 243.988 -98.522 248.053 -81.2496 242.557C-64.1189 237.114 -49.6438 226.986 -31.2638 223.818C13.0737 216.187 57.9269 249.605 101.162 261.477C147.622 274.219 177.679 249.57 203.575 224.206C228.593 199.733 267.725 188.58 308.489 193.157C350.802 197.925 391.372 217.875 430.798 235.397C467.53 251.742 499.931 276.889 522.204 308.103" stroke="#00C8CB" strokeMiterlimit="10" />
  </svg>
);

function Terms({ modalOpen, setModalOpen }: {modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  return (
    <TermsStyles className="blockPaddingBottom">
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ContactForm handleClose={() => setModalOpen(false)} />
        </Modal>
      )}

      <div className="background">
        {background}
        {backgroundTablet}
        {backgroundMobile}
      </div>

      <div className="container">
        <div className="titleWrapper">
          <h1>Terms of Service</h1>

          <LinksPath
            pathArr={
                [
                  {
                    path: '',
                    name: 'Terms of Service',
                  },
                ]
              }
          />
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">1. Terms</h2>

          <p className="textBase18">
            By accessing this Website, accessible from qust.app, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">2. Use License</h2>

          <p className="textBase18">
            {'Permission is granted to temporarily download one copy of the materials on QuST\'s Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:'}
          </p>

          <ul>
            <li className="textBase18"><TextWithDot text="modify or copy the materials;" /></li>
            <li className="textBase18"><TextWithDot text="use the materials for any commercial purpose or for any public display;" /></li>
            <li className="textBase18"><TextWithDot text="attempt to reverse engineer any software contained on QuST's Website;" /></li>
            <li className="textBase18"><TextWithDot text="remove any copyright or other proprietary notations from the materials; or" /></li>
            <li className="textBase18"><TextWithDot text='transferring the materials to another person or "mirror" the materials on any other server.' /></li>
          </ul>

          <p className="textBase18">
            This will let QuST to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service has been created with the help of the
            {' '}
            <a className="link" target="_blank" href="https://www.termsofservicegenerator.net/" rel="noreferrer">Terms Of Service Generator.</a>
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">3. Disclaimer</h2>

          <p className="textBase18">
            {'All the materials on QuST\'s Website are provided "as is". QuST makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, QuST does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">4. Limitations</h2>

          <p className="textBase18">
            {'            QuST or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on QuST\'s Website, even if QuST or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">5. Revisions and Errata</h2>

          <p className="textBase18">
            {'            The materials appearing on QuST\'s Website may include technical, typographical, or photographic errors. QuST will not promise that any of the materials in this Website are accurate, complete, or current. QuST may change the materials contained on its Website at any time without notice. QuST does not make any commitment to update the materials.'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">6. Links</h2>

          <p className="textBase18">
            {'            QuST has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by QuST of the site. The use of any linked website is at the user\'s own risk.'}
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">7. Site Terms of Use Modifications</h2>

          <p className="textBase18">
            QuST may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">8. Your Privacy</h2>

          <p className="textBase18">
            Please read our Privacy Policy.
          </p>
        </div>

        <div className="textWrapper">
          <h2 className="textSemiBold32">9. Governing Law</h2>

          <p className="textBase18">
            {'Any claim related to QuST\'s Website shall be governed by the laws of us without regards to its conflict of law provisions.'}
          </p>
        </div>
      </div>
    </TermsStyles>
  );
}

export default Terms;
