import React, { Fragment, JSX } from 'react';
import { HowToUseStyles } from './HowToUseStyles';
import { howToUseData } from '../../../constants/constants';
import TextWithDot from '../../other/textWithDot/TextWithDot';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

function HowToUse() {
  return (
    <HowToUseStyles className="blockPaddingBottom">
      <div className="container">
        <h2 className="textSemiBold32">This is so easy to use!</h2>

        <div className="content">
          {howToUseData.map((item, index) => (
            <InView key={item.title} triggerOnce threshold={0.4} onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="itemWrapper">
              <div className={`item item${index + 1}`}>
                <picture>
                  <source srcSet={item.image.desktop} media="(min-width: 1110px)" />
                  <source srcSet={item.image.tablet} media="(min-width: 768px)" />

                  <img src={item.image.mobile} alt={`Qust - ${item.title}`} />
                </picture>

                <div className="itemContent">
                  <div className="number textSemiBold18">
                    {index + 1}
                  </div>

                  {item.arrow && (
                    <div className="arrow">
                      {item.arrow.small}
                      {item.arrow.big}
                    </div>
                  )}

                  <h3 className="textSemiBold18 itemTitle">{item.title}</h3>

                  {item.icons && (
                    <ul className="iconsList">
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      {item.icons.map((icon, index) => <li key={index}>{icon}</li>)}
                    </ul>
                  )}
                  {item.listData && (
                    <ul className="textList">
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      {item.listData.map((text) => <li key={text}><TextWithDot text={text} /></li>)}
                    </ul>
                  )}
                </div>
              </div>
            </InView>
          ))}
        </div>
      </div>
    </HowToUseStyles>
  );
}

export default HowToUse;
