import styled from 'styled-components';

export const QustIsStyles = styled.section`
  h2 {
    text-align: center;
    
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
  
  .blockNavigationContainer {
    max-width: 1110px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    overflow: hidden;
    padding-left: 16px;
    
    margin-bottom: 16px;
    
    @media (min-width: 1110px) {
      padding-left: 0;
    }

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }
    
    .line {
      position: absolute;
      z-index: 1;

      background: var(--blue-1, #005273);
      bottom: 0;
      height: 3px;
      border-radius: 4px;
      transition: 0.3s ease all;
    }

    .buttonsWrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 25px;
      overflow: auto hidden;

      ::-webkit-scrollbar {
        display: none;
      }
      
      padding-bottom: 4px;
      
      border-bottom: 1px solid var(--grey-4, #D9D9D9);
      
      button {
        position: relative;
        
        color: var(--grey-6, #595959);

        transition: 0.3s ease color;
        white-space: nowrap;

        &.active {
          color: var(--grey-8, #141414);
        }
      }
    }
  }
  
  .businessList {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;

    justify-content: space-between;

    @media (min-width: 710px) {
      grid-template-columns: auto auto auto;
    }
  }
  
  .managerList {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    
    @media (min-width: 710px) {
      grid-template-columns: auto auto;
    }
  }  
  
  .consultantsList {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    
    @media (min-width: 710px) {
      grid-template-columns: auto auto auto;
    }
  }  
  
  .crmList {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;

    @media (min-width: 710px) {
      grid-template-columns: auto auto;
    }
  }
`;
