import React, { useMemo } from 'react';
import { ButtonProps } from '../../../enteties/button/ButtonProps';
import { ButtonStyles } from './ButtonStyles';

const buttonLoaderColors = {
  grey: '#8C8C8C',
  mainFilled: '#FDFDFD',
  mainNotFilled: '#FDB17B',
  red: '#FF7875',
};

function Button({
  icon,
  error,
  text,
  theme = 'grey',
  size = 'XL',
  onClick,
  isLoading,
  type = 'button',
  disabled,
  fullWidth,
  extraClasses,
  title,
  iconPosition = 'iconRight',
}: ButtonProps) {
  return (
    <ButtonStyles title={title} onClick={onClick} className={`button textSemiBold16 ${iconPosition} ${error ? 'red' : theme} ${size} ${isLoading ? 'loading' : ''} ${extraClasses ?? ''}`} style={fullWidth ? { width: '100%' } : {}} disabled={disabled} type={type}>

      {text && text}
      {icon && icon}

    </ButtonStyles>
  );
}

export default Button;
