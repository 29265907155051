import React from 'react';

function LinkedinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1182_28098)">
        <path
          fill="#007AB9"
          d="M12 24.521c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12z"
        />
        <path
          fill="#F1F2F2"
          d="M19.169 13.488v4.947H16.3v-4.616c0-1.159-.414-1.95-1.452-1.95-.793 0-1.264.533-1.472 1.049-.075.184-.095.44-.095.699v4.818h-2.868s.038-7.818 0-8.627h2.869v1.222l-.02.029h.02v-.029c.38-.586 1.06-1.425 2.585-1.425 1.887 0 3.302 1.233 3.302 3.883zM7.412 5.649c-.981 0-1.623.644-1.623 1.49 0 .829.623 1.492 1.586 1.492h.018c1 0 1.623-.663 1.623-1.491-.02-.846-.622-1.49-1.604-1.49zM5.96 18.435h2.867V9.808H5.96v8.627z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1182_28098">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .521)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedinIcon;
