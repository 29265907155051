import React from 'react';

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1182_28102)">
        <path
          fill="url(#paint0_linear_1182_28102)"
          d="M24 12.521c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12s12-5.372 12-12z"
        />
        <path
          fill="#fff"
          fillOpacity="0.063"
          d="M21.031 4.627c-1.749 3.212-4.223 6.524-7.315 9.616-3.091 3.091-6.404 5.56-9.61 7.31a11.427 11.427 0 01-.587-.546 12 12 0 1116.966-16.97c.19.189.372.386.546.59z"
        />
        <path
          fill="#fff"
          d="M14.841 6.206H9.157A3.474 3.474 0 005.684 9.68v5.684a3.474 3.474 0 003.473 3.474h5.684a3.474 3.474 0 003.474-3.474V9.68a3.474 3.474 0 00-3.474-3.474zm2.369 8.659a2.867 2.867 0 01-2.868 2.867H9.656a2.867 2.867 0 01-2.867-2.867v-4.686A2.867 2.867 0 019.656 7.31h4.686a2.868 2.868 0 012.868 2.868v4.686z"
        />
        <path
          fill="#fff"
          d="M14.303 10.241l-.03-.03-.025-.025a3.183 3.183 0 00-2.249-.928 3.24 3.24 0 00-3.218 3.263c0 .864.34 1.693.948 2.307a3.189 3.189 0 002.277.948 3.274 3.274 0 002.297-5.535zm-2.304 4.421a2.14 2.14 0 01-1.554-3.644 2.142 2.142 0 013.665 1.503A2.124 2.124 0 0112 14.662zm4.173-5.554a.77.77 0 01-1.058.718.779.779 0 01.294-1.492c.178 0 .351.063.488.177l.016.016c.025.02.049.044.07.07l.017.018c.113.14.174.313.173.493z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1182_28102"
          x1="3.515"
          x2="20.485"
          y1="4.036"
          y2="21.007"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAE100" />
          <stop offset="0.15" stopColor="#FCB720" />
          <stop offset="0.3" stopColor="#FF7950" />
          <stop offset="0.5" stopColor="#FF1C74" />
          <stop offset="1" stopColor="#6C1CD1" />
        </linearGradient>
        <clipPath id="clip0_1182_28102">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .521)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InstagramIcon;
