import React from 'react';
import { FooterStyles } from './FooterStyles';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/Logo';
import LinkedinIcon from '../../../assets/icons/footer/LinkedinIcon';
import FacebookIcon from '../../../assets/icons/footer/FacebookIcon';
import InstagramIcon from '../../../assets/icons/footer/InstagramIcon';
import TwitterIcon from '../../../assets/icons/footer/TwitterIcon';

const socialMedias = [
  {
    path: '1',
    icon: <FacebookIcon />,
  },
  {
    path: '2',
    icon: <LinkedinIcon />,
  },
  {
    path: '3',
    icon: <InstagramIcon />,
  },
  {
    path: '4',
    icon: <TwitterIcon />,
  },
];

function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <div className="logoAndSocialMediasWrapper">
          <Link to="/"><Logo /></Link>

          <div className="socialMedias">
            {socialMedias.map((socialMedia) => <a key={socialMedia.path} href={socialMedia.path} target="_blank" rel="noreferrer">{socialMedia.icon}</a>)}
          </div>
        </div>

        <div className="termsWrapper">
          <Link className="colorBlack textSemiBold16 animationUnderline" to="/terms-of-service">
            Terms of Service
          </Link>
          <Link className="colorBlack textSemiBold16 animationUnderline" to="/privacy-policy">
            Privacy Policy
          </Link>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
