import styled from 'styled-components';

export const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.60);
  display: grid;
  place-items: center;
  padding: 20px;
  box-sizing: border-box;
  
  .modalBody {
    padding: 16px;
    box-sizing: border-box;
    background: #FFF;
    border-radius: 4px;
    
    @media (min-width: 600px) {
      padding: 40px;
    }
  }
`;
