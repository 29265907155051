import styled from 'styled-components';

import bgMobile from '../../../assets/images/banner/bg-mobile.svg';
import bgTablet from '../../../assets/images/banner/bg-tablet.svg';
import bgDesktop from '../../../assets/images/banner/bg-desktop.svg';

export const BannerStyles = styled.section`
  padding-top: 44px;

  color: #000;

  @media (min-width: 768px) {
    padding-top: 60px;
  }

  @media (min-width: 1024px) {
    padding-top: 102px;
  }
  
  .contentWrapper {
    position: relative;
    
    .background {
      position: absolute;
      top: 0;

      background-image: url(${bgMobile});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      
      height: 305px;
      width: 100%;

      @media (min-width: 768px) {
        background-image: url(${bgTablet});
        top: -70px;
        
        height: 397px;
      }

      @media (min-width: 1024px) {
        top: -20px;
        background-image: url(${bgDesktop});
      }
    }
  }
  
  .container {
    position: relative;
  }
  
  .titleWrapper {
    text-align: center;
    
    padding-bottom: 24px;

    @media (min-width: 768px) {
      padding-bottom: 32px;
    }

    @media (min-width: 1024px) {
      padding-bottom: 40px;
    }

    h1 {
      text-align: center;

      font-size: 28px;
      font-weight: 600;

      padding-bottom: 16px;

      &:nth-child(1) {
        display: none;

        @media (min-width: 600px) {
          display: block;
        }
      }

      &:nth-child(2) {
        @media (min-width: 600px) {
          display: none;
        }
      }

      .textByLetter {
        background: var(--blue-2, #00C8CB);
        padding: 5px 10px;

        @media (min-width: 600px) {
          padding: 10px;
        }
      }

      @media (min-width: 768px) {
        padding-bottom: 32px;

        font-size: 32px;
      }

      @media (min-width: 1024px) {
        padding-bottom: 40px;

        font-size: 54px;
      }
    }
  }
  
  .reviewsList {
    display: block;
    gap: 24px;
    
    margin-bottom: 32px;
    
    @media (min-width: 1024px) {
      gap: 30px;

      margin-bottom: 40px;
    }

    &.contentDesktop {
      display: none;

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
    
    &.contentMobile {
      .swiper-slide {
        width: fit-content;
      }
      
      .review {
        width: 238px;
      }
      
      .swiper-wrapper {
        padding-bottom: 5px;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
    
    .review {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      padding: 16px;
      box-sizing: border-box;
      height: auto;
      
      border-radius: 4px;
      background: var(--grey-1, #FDFDFD);
      box-shadow: 1px 1px 12px 0px rgba(6, 6, 6, 0.12);
      
      @media (min-width: 1024px) {
        gap: 24px;
      }
      
      .stars {
        display: flex;
        gap: 8px;
      }
      
      .reviewText {
        flex-grow: 1;
      }
      
      .userContainer {
        display: flex;
        gap: 12px;

        @media (min-width: 1024px) {
          gap: 16px;
        }
        
        .nameAndPositionContainer {
          display: flex;
          flex-direction: column;
          
          .name {
            font-weight: 500;
          }

          .position {
            color: var(--grey-6, #595959);
          }
        }

      }
    }
  }

  .button {
    width: 100%;
    margin: auto;

    @media (min-width: 768px) {
      max-width: 390px;
    }

    @media (min-width: 1024px) {
      max-width: 350px;
    }
  }
`;
