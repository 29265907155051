import React from 'react';
import { SloganStyles } from './SloganStyles';
import { Link } from 'react-router-dom';

function Slogan({ setModalOpen }: {setModalOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  return (
    <SloganStyles>
      <div className="container">
        <svg className="greenSVG" xmlns="http://www.w3.org/2000/svg" width="362" height="84" viewBox="0 0 362 84" fill="none">
          <path d="M45 83.5C45 -91.2981 186.702 -233 361.5 -233" stroke="#00C8CB" strokeWidth="90" />
        </svg>

        <svg className="orangeCircleSVG" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#FEC9A4" />
        </svg>

        <svg className="orangeSVG" xmlns="http://www.w3.org/2000/svg" width="362" height="400" viewBox="0 0 362 400" fill="none">
          <path d="M-2.76693e-05 -133C174.798 -133 316.5 8.70186 316.5 183.5C316.5 358.298 174.798 500 0 500" stroke="#FC812B" strokeWidth="90" />
        </svg>

        <svg className="greenCircleSVG" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#00C8CB" />
        </svg>

        <div className="contentWrapper">
          <h2>Our goal is to provide you tools to jumpstart and grow your business</h2>

          <button type="button" onClick={() => setModalOpen(true)} className="buttonWhite">Let’s do it</button>
        </div>
      </div>
    </SloganStyles>
  );
}

export default Slogan;
