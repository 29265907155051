import img1Desktop from '../assets/images/howToUse/img1-desktop.png';
import img2Desktop from '../assets/images/howToUse/img2-desktop.png';
import img3Desktop from '../assets/images/howToUse/img3-desktop.png';
import img4Desktop from '../assets/images/howToUse/img4-desktop.png';
import img1Tablet from '../assets/images/howToUse/img1-tablet.png';
import img2Tablet from '../assets/images/howToUse/img2-tablet.png';
import img3Tablet from '../assets/images/howToUse/img3-tablet.png';
import img4Tablet from '../assets/images/howToUse/img4-tablet.png';
import img1Mobile from '../assets/images/howToUse/img1-mobile.png';
import img2Mobile from '../assets/images/howToUse/img2-mobile.png';
import img3Mobile from '../assets/images/howToUse/img3-mobile.png';
import img4Mobile from '../assets/images/howToUse/img4-mobile.png';

import {
  arrow1big,
  arrow1small,
  arrow2big,
  arrow2small, arrow3big,
  arrow3small,
  iconsItem2,
} from '../assets/icons/howToUse/icons';
import { JSX } from 'react';

type HowToUseItem = {
  title: string,
  image: {
    mobile: string,
    tablet: string,
    desktop: string,
  },
  arrow?: {
    small: JSX.Element,
    big: JSX.Element,
  },
  icons?: JSX.Element[],
  listData?: string[],
}

export const howToUseData: HowToUseItem[] = [
  {
    title: 'Create any form you need',
    image: {
      mobile: img1Mobile,
      tablet: img1Tablet,
      desktop: img1Desktop,
    },
    arrow: {
      small: arrow1small,
      big: arrow1big,
    },
    listData: [
      'lead capture',
      'customer support',
      'tickets collection',
      'feedback receiving',
    ],
  },
  {
    title: 'Connect it to your website',
    image: {
      mobile: img2Mobile,
      tablet: img2Tablet,
      desktop: img2Desktop,
    },
    arrow: {
      small: arrow2small,
      big: arrow2big,
    },
    icons: iconsItem2,
  },
  {
    title: 'Start getting leads into CRM',
    image: {
      mobile: img3Mobile,
      tablet: img3Tablet,
      desktop: img3Desktop,
    },
    arrow: {
      small: arrow3small,
      big: arrow3big,
    },
    listData: [
      'manage status funnel',
      'nurture your clients',
      'grow your sales',
    ],
  },
  {
    title: 'Try any tool you need for free',
    image: {
      mobile: img4Mobile,
      tablet: img4Tablet,
      desktop: img4Desktop,
    },
    listData: [
      'Advanced CRM, Forms and Analytics',
      'E-mail automated flows',
      'Pop-up and Push management tools',
      'Integrated chat',
      'AI marketing assistant',
    ],
  },
];
