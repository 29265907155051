import React, { CSSProperties, useCallback, useRef } from 'react';
import { SquareCheckboxStyles } from './SquareCheckboxStyles';
import { ErrorMessage, useFormikContext } from 'formik';

interface CheckboxProps {
  name: string;
  value: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  extraBlockStyles?: CSSProperties;
  checkboxWrapperStyles?: CSSProperties;
  customizedCheckboxContainerStyles?: CSSProperties;
  label?: string;
  disabled?: boolean;
  error?: string;
  showError?: boolean;
  children: any;
}

const checkMark = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M11.6673 3.5L5.25065 9.91667L2.33398 7" stroke="#FC812B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const SquareCheckbox: React.FC<CheckboxProps> = ({
  name,
  value,
  onChange,
  extraBlockStyles,
  checkboxWrapperStyles,
  customizedCheckboxContainerStyles,
  label,
  disabled,
  error,
  showError,
  children,
}) => {
  const inputRef = useRef(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
    }
  }, [inputRef]);

  return (
    <SquareCheckboxStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label && <span>{label}</span>}
        <input className="checkboxDefault" type="checkbox" id={name} checked={value} ref={inputRef} onChange={onChange} disabled={disabled} />
        <div className="checkboxWrapper" style={checkboxWrapperStyles}>
          <div style={customizedCheckboxContainerStyles}>
            <div
              className={value ? 'customizedCheckbox customizedCheckboxChecked' : 'customizedCheckbox'}
              style={label ? { marginTop: 16 } : {}}
              onChange={disabled ? () => null : handleOnClick}
            >
              {value && checkMark}
            </div>
          </div>
          {children && (
          <div className="checkboxText">
            {children}
          </div>
          )}
        </div>
        {showError && (
          <div className="formErrorContainer">
            {error && <span>{error}</span>}
          </div>
        )}
      </label>
    </SquareCheckboxStyles>
  );
};

export default SquareCheckbox;
