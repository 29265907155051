import React from 'react';
import { SuitesForStyles } from './SuitesForStyles';

const listData = [
  'Consulting',
  'Legal services',
  'Online education',
  'Marketing agencies',
  'Experts',
  'IT outsources',
  'Small businesses',
  'Feedback collection',
  'Customer support',
];

function SuitesFor() {
  return (
    <SuitesForStyles className="blockPaddingBottom">
      <div className="container">
        <div className="titleWrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="452" height="49" viewBox="0 0 452 49" fill="none">
            <path opacity="0.2" d="M219.657 0.63655C191.44 -1.81102 136.473 2.94867 84.359 12.3514L72.0572 14.574C37.4773 20.8319 18.7972 25.1947 5.56795 30.1039C0.404671 32.0203 -0.0465741 32.2759 0.00332319 33.2559C0.0792538 34.6858 0.901474 34.5762 10.4112 31.8707C20.2519 29.071 29.0143 27.2535 52.9823 23.0416C133.089 8.96393 170.299 4.84853 211.598 5.50106C219.691 5.62827 232.479 7.49869 232.392 8.54226C232.276 9.93566 223.851 13.6188 204.461 20.7518C180.605 29.5268 166.029 35.515 164.65 37.1051C162.293 39.8235 164.431 42.2958 169.469 42.681C172.347 42.9012 173.008 42.8659 186.383 41.7623C224.783 38.5986 249.236 37.6539 282.234 38.0603C332.486 38.6798 364.371 40.6869 424.103 46.9931C445.566 49.2593 446.681 49.3252 450.741 48.562L450.812 48.549C452.115 48.3099 452.472 46.3995 451.245 45.7988C448.087 44.2558 445.938 43.9095 427.329 41.9578C364.948 35.4137 332.013 33.2888 284.082 32.7211C258.874 32.4219 247.616 32.5845 229.165 33.515C216.551 34.151 183.204 36.4525 178.513 37.0097C175.954 37.3147 187.335 32.74 207.263 25.455C232.47 16.2383 237.563 13.501 237.965 8.95922C238.417 3.83794 234.137 1.89214 219.657 0.63655Z" fill="#00C8CB" />
          </svg>

          <h2 className="textSemiBold32">Best suites for</h2>
        </div>

        <ul>
          {listData.map((text) => (
            <li key={text}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#00C8CB" />
                <path d="M16.8012 8.39941L10.2012 14.9994L7.20117 11.9994" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>

              <span className="textBase18">{text}</span>
            </li>
          ))}
        </ul>
      </div>
    </SuitesForStyles>
  );
}

export default SuitesFor;
