import React, { useEffect } from 'react';
import { MainLayoutStyles } from './MainLayoutStyles';
import Header from '../../mainPage/header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from '../../mainPage/footer/Footer';

function MainLayout({ setModalOpen }: {setModalOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, [navigate]);

  return (
    <MainLayoutStyles>
      <Header setModalOpen={setModalOpen} />

      <Outlet />

      <Footer />
    </MainLayoutStyles>
  );
}

export default MainLayout;
