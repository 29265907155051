import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { QustIsStyles } from './QustIsStyles';
import classNames from 'classnames';
import TextWithDot from '../../other/textWithDot/TextWithDot';
import { useWindowSize } from 'usehooks-ts';

const navigationData = [
  'For business owner',
  'For marketing manager',
  'For consultant',
  'For CRM integrator',
];

const businessListData = [
  'free lead capture',
  '10 minutes no tech skills setup',
  'free CRM solution',
  'free analytical tools',
  'features to scale your business',
  'try before you pay',
];

const managerListData = [
  '10 minutes no tech skills setup',
  'features to add even more value to your skillcap',
  'free and easy to use form builder to capture leads',
  'try before your client pay',
];

const consultantsListData = [
  'get leads from online and offline',
  'nurture your potential clients',
  'free CRM',
  '10 minutes no tech skills setup',
  'try any tool for free before you pay',
];

const crmListData = [
  'CRM that is ultimately easy to setup - spend less resources',
  'dedicated partners support',
  'get bonuses with our partnership program',
];

function QustIs() {
  const [activeStep, setActiveStep] = useState<number>(1);
  const activeButtonRef = useRef<HTMLButtonElement | null>(null);
  const buttonsWrapperRef = useRef<HTMLDivElement | null>(null);
  const lineElement = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowSize();

  useEffect(() => {
    const activeButton = activeButtonRef.current;

    if (activeButton && lineElement.current) {
      const buttonWidth = activeButton.offsetWidth;
      const buttonLeft = activeButton.offsetLeft;

      lineElement.current.style.width = `${buttonWidth}px`;
      lineElement.current.style.transform = `translateX(${buttonLeft}px)`;

      const buttonsWrapper = buttonsWrapperRef.current;
      if (buttonsWrapper) {
        const containerWidth = buttonsWrapper.clientWidth;
        const buttonLeftRelative = activeButton.offsetLeft - buttonsWrapper.scrollLeft;

        if (buttonLeftRelative < 0 || buttonLeftRelative + buttonWidth > containerWidth) {
          buttonsWrapper.scrollLeft = activeButton.offsetLeft - (containerWidth - buttonWidth) / 2;
        }
      }
    }
  }, [activeStep, width]);

  return (
    <QustIsStyles className="blockPaddingBottom">
      <h2 className="textSemiBold32 titlePaddingBottom">QuST is...</h2>

      <div className="blockNavigationContainer">
        <div className="buttonsWrapper" ref={buttonsWrapperRef}>
          {navigationData.map((text, index) => (
            <button
              ref={index + 1 === activeStep ? activeButtonRef : null}
              className={classNames('textSemiBold16', { active: activeStep === index + 1 })}
              key={text}
              type="button"
              title={`Перейти на крок "${text}"`}
              onClick={() => setActiveStep(index + 1)}
            >
              {text}
            </button>
          ))}

          <div className="line" ref={lineElement} />
        </div>
      </div>
      <div className="container">
        {activeStep === 1 && (
          <ul className="businessList">
            {businessListData.map((text) => <li key={text}><TextWithDot text={text} /></li>)}
          </ul>
        )}

        {activeStep === 2 && (
          <ul className="managerList">
            {managerListData.map((text) => <li key={text}><TextWithDot text={text} /></li>)}
          </ul>
        )}

        {activeStep === 3 && (
          <ul className="consultantsList">
            {consultantsListData.map((text) => <li key={text}><TextWithDot text={text} /></li>)}
          </ul>
        )}

        {activeStep === 4 && (
          <ul className="crmList">
            {crmListData.map((text) => <li key={text}><TextWithDot text={text} /></li>)}
          </ul>
        )}

      </div>
    </QustIsStyles>
  );
}

export default QustIs;
