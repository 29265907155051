import React from 'react';

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1182_28094)">
        <path
          fill="#3B5998"
          d="M12 24.521c6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12s-12 5.373-12 12c0 6.628 5.373 12 12 12z"
        />
        <path
          fill="#fff"
          d="M15.018 12.991h-2.141v7.845H9.633V12.99H8.09v-2.757h1.543V8.45c0-1.276.606-3.273 3.273-3.273l2.403.01v2.676h-1.744c-.286 0-.688.143-.688.751v1.623h2.425l-.284 2.754z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1182_28094">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .521)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FacebookIcon;
