import styled from 'styled-components';

export const MainLayoutStyles = styled.div`
  display: flex;
  flex-direction: column;
  
  min-height: 100vh;

  color: var(--grey-8, #141414);

  main {
    flex-grow: 1;
  }

  .blockPaddingBottom {
    padding-bottom: 64px;
    
    @media (min-width: 768px) {
      padding-bottom: 86px;
    }
    
    @media (min-width: 1024px) {
      padding-bottom: 140px;
    }
  }
`;
