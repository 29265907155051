import styled from 'styled-components';

export const SuitesForStyles = styled.section`
  .titleWrapper {
    position: relative;
    text-align: center;
    margin: auto auto 24px auto;
    
    max-width: 340px;
    width: 100%;
    
    @media (min-width: 768px) {
      margin-bottom: 32px;
      max-width: 360px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 40px;
      max-width: 452px;
    }
    
    svg {
      width: 100%;
      
      position: absolute;
      left: 0;
    }
  }
  
  ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (min-width: 400px) {
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
    }
    
    @media (min-width: 682px) {
      grid-template-columns: repeat(3, 1fr);
    }
    
    li {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      max-width: 203px;
      
      white-space: nowrap;
    }
  }
`;
