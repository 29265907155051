import styled from 'styled-components';

export const ThankYouStyles = styled.main`
  padding: 40px 0;
  
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media (min-width: 768px) {
      gap: 32px;
    }
  }
  
  svg {
    width: 100%;
    height: auto;
    
    @media (min-width: 768px) {
      width: 568px;
      height: 325px;
    }

    @media (min-width: 1024px) {
      width: 632px;
      height: 362px;
    }
  }
  
  .textWrapper {
    margin: auto;
    
    max-width: 834px;
  }
  
  h1 {
    margin-bottom: 12px;
    
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    
    @media (min-width: 768px) {
      margin-bottom: 16px;
    }

    @media (min-width: 1024px) {

    }
  }
  
  .button {
    margin: auto;
  }
`;
