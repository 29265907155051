import React, { useCallback, useRef, useState } from 'react';
import { SwiperCenteredSlidesPerViewAutoStyles } from './SwiperCenteredSlidesPerViewAutoStyles';
import 'swiper/css';

import { Swiper } from 'swiper/react';
import { FreeMode, Pagination, Navigation } from 'swiper/modules';

function SwiperCenteredSlidesPerViewAuto({
  buttonBorderColor,
  initialSlide = 0,
  offsetLeft,
  children,
  buttonsIconsColor,
  loop = true,
  disabledPrev = true,
  disabledNext = true,
  linkButtonText,
  link,
  centeredSlides = true,
  freeMode = false,
  offsetRight = 0,
  spaceBeetwen = 20,
  slidePerView = 1,
}: {
  children: any,
  centeredSlides?: boolean,
  buttonsIconsColor?: string,
  loop?: boolean,
  disabledPrev?: boolean,
  disabledNext?: boolean,
  link?: string,
  linkButtonText?: string,
  freeMode?: boolean,
  offsetRight?: number,
  offsetLeft?: number,
  initialSlide?: number,
  buttonBorderColor?: string,
  spaceBeetwen?: number,
  slidePerView?: number,
}) {
  const sliderRef = useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [slidesCount, setSlidesCount] = useState<number>(0);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-ignore
    const currentIndex = sliderRef.current.swiper.activeIndex;
    const newIndex = currentIndex - slidePerView;
    // @ts-ignore
    sliderRef.current.swiper.slideTo(newIndex);
  }, [slidePerView]);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    // @ts-ignore
    const currentIndex = sliderRef.current.swiper.activeIndex;
    const newIndex = currentIndex + slidePerView;

    // @ts-ignore
    sliderRef.current.swiper.slideTo(newIndex);
  }, [slidePerView]);

  return (
    <SwiperCenteredSlidesPerViewAutoStyles className="sliderWrapper">
      <div className="sliderContent">
        <Swiper
          allowTouchMove={false}
          slidesPerView="auto"
          initialSlide={initialSlide}
          centeredSlides={centeredSlides}
          spaceBetween={8}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          loop={loop}
          className="mySwiper"
          // @ts-ignore
          ref={sliderRef}
          breakpoints={{
            // when window width is >= 640px
            300: {
              spaceBetween: 8,
            },
            600: {
              spaceBetween: 40,
            },
          }}
          modules={[FreeMode, Pagination, Navigation]}
          freeMode={freeMode}
          // @ts-ignore
          onActiveIndexChange={(slider) => {
            setActiveSlideIndex(slider.activeIndex);
          }}
          onSlidesLengthChange={(swiper: any) => setSlidesCount(swiper.slides.length)}
          slidesOffsetAfter={offsetRight}
          slidesOffsetBefore={offsetLeft}
        >
          {children}
        </Swiper>

        <div className="sliderNavigation">
          <button ref={navigationPrevRef} type="button" className="prevButton">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 18L15 12L9 6" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>

          <button ref={navigationNextRef} type="button" className="nextButton">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 18L15 12L9 6" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </SwiperCenteredSlidesPerViewAutoStyles>
  );
}

export default SwiperCenteredSlidesPerViewAuto;
