import React, { useState } from 'react';
import { CasesStyles } from './CasesStyles';
import classNames from 'classnames';
import showTextByLetter from '../../../utils/ShowTextByLetter/ShowTextByLetter';

const menuItems = [
  'Marketing Agency',
  'Informational business',
  'Consultant',
  'Event agency',
  'IT outsource company',
];

const textItems = [
  'In our previous projects we needed to spend money and from 3 days to 2 weeks to get CRM integrated. With QuST it takes us couple minutes and no afford. All you need to do is to connect your website, set form and work with clients.',
  'I simply created a form, put it into my Instagram profile and started getting potential clients. And I am not even a technical person at all. That\'s amazing!',
  'I use QuST forms on my website, in my social media profiles and as QR code on my business card. You should definitely try it!',
  'We use sneaky QR codes done with QuST form builder to advertise our event agency everywhere we need. This is perfect way to combine offline and online lead generation in one tool!',
  'We use QuST for lead capturing on our website and from different events using form builder. I love it\'s simple but efficient CRM to work with our leads.',
];

function Cases() {
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number>(0);

  return (
    <CasesStyles>
      <h2 className="textSemiBold32">Use cases</h2>

      <div className="container">
        <div className="menu">
          {menuItems.map((text, index) => (
            <button
              type="button"
              key={text}
              className={classNames({ active: activeMenuItemIndex === index })}
              onClick={() => setActiveMenuItemIndex(index)}
            >
              <span className="textSemiBold22">{text}</span>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9 18L15 12L9 6" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          ))}
        </div>
        <div className="textContainer">
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="46" viewBox="0 0 56 46" fill="none">
            <path d="M45.0142 23.4998C51.2288 23.4998 55.4167 27.8223 55.4167 34.2269C55.4167 39.9902 50.6033 44.7917 44.084 44.7917C36.9431 44.7917 31.6667 39.0283 31.6667 30.225C31.6667 10.2156 46.2571 2.21187 55.4167 1.25V10.0533C49.206 11.1735 42.2235 17.4158 41.9108 24.3033C42.2235 24.145 43.4625 23.4998 45.0142 23.4998ZM13.3515 23.4998C19.5581 23.4998 23.75 27.8223 23.75 34.2269C23.75 39.9902 18.9367 44.7917 12.4173 44.7917C5.27646 44.7917 0 39.0283 0 30.225C0 10.2156 14.5904 2.21187 23.75 1.25V10.0533C17.5394 11.1735 10.5569 17.4158 10.2442 24.3033C10.5569 24.145 11.7958 23.4998 13.3515 23.4998Z" fill="#FC812B" />
          </svg>

          <p>{textItems[activeMenuItemIndex]}</p>
        </div>
      </div>
    </CasesStyles>
  );
}

export default Cases;
