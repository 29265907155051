import styled from 'styled-components';

export const SloganStyles = styled.section`
  background: #005273;
  overflow: hidden;
  
  .container {
    position: relative;

    .greenSVG {
      position: absolute;
      
      height: 60px;
      left: -34px;

      @media (min-width: 768px) {
        height: 70px;
        left: -15px;
      }

      @media (min-width: 1024px) {
        height: 125px;
        left: 0px;
      }
    }

    .greenCircleSVG {
      position: absolute;
      display: none;

      @media (min-width: 768px) {
        display: block;
        right: 40px;
        bottom: 70px;
      }
    }
    
    .orangeCircleSVG {
      position: absolute;
      
      bottom: 10px;
    }

    .orangeSVG {
      position: absolute;
      display: none;

      @media (min-width: 768px) {
        display: block;
        right: 150px;
      }
    }

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      
      padding: 62px 0;

      @media (min-width: 768px) {
        padding: 80px 0;
      }

      @media (min-width: 1024px) {
        padding: 107px 0;
      }
    }

    h2 {
      color: #FFF;

      font-size: 22px;
      font-weight: 600;
      line-height: 128.023%;
      letter-spacing: 0.44px;

      @media (min-width: 768px) {
        font-size: 24px;
        letter-spacing: 0.48px;
        max-width: 520px;
      }

      @media (min-width: 1024px) {
        max-width: 720px;
        font-size: 40px;
        letter-spacing: 0.8px;
      }
    }
  }

  .buttonWhite {
    min-width: 270px;
    width: 100%;

    @media (min-width: 768px) {
      max-width: 320px;
    }
  }
`;
