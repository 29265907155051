import React, { useState } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Main from './pages/main/Main';
import MainLayout from './components/layouts/mainLayaout/MainLayout';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import Terms from './pages/terms/Terms';
import ThankYou from './pages/thankYou/ThankYou';

function App() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <Routes>
      <Route element={<MainLayout setModalOpen={setModalOpen} />}>
        <Route path="/" element={<Main modalOpen={modalOpen} setModalOpen={setModalOpen} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy modalOpen={modalOpen} setModalOpen={setModalOpen} />} />
        <Route path="/terms-of-service" element={<Terms modalOpen={modalOpen} setModalOpen={setModalOpen} />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
