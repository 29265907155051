import React from 'react';

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1199_10843)">
        <path
          fill="#000"
          stroke="#000"
          d="M23.5 12.521c0 6.352-5.149 11.5-11.5 11.5S.5 18.873.5 12.521c0-6.35 5.149-11.5 11.5-11.5s11.5 5.15 11.5 11.5z"
        />
        <path
          fill="#fff"
          d="M13.142 11.603l4.467-5.082H16.55l-3.879 4.412-3.098-4.412H6l4.685 6.672L6 18.522h1.059l4.096-4.66 3.272 4.66H18l-4.859-6.92zm-1.45 1.649l-.475-.665L7.44 7.302h1.626l3.048 4.266.475.665 3.962 5.545h-1.626l-3.233-4.525z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1199_10843">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .521)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TwitterIcon;
