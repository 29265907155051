import React from 'react';
import Logo from '../../../assets/Logo';
import { HeaderStyles } from './HeaderStyles';
import { Link, useLocation } from 'react-router-dom';

function Header({ setModalOpen }: {setModalOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  const location = useLocation();

  return (
    <HeaderStyles>
      <div className="content container">
        <Link to="/">
          <Logo />
        </Link>

        {!location.pathname.includes('thank-you') && (
          <div className="buttonsWrapper">
            <button type="button" onClick={() => setModalOpen(true)} className="button" title="Sign up">
              Join us
            </button>
          </div>
        )}
      </div>
    </HeaderStyles>
  );
}

export default Header;
