import styled from 'styled-components';

import bgMobile from '../../../assets/images/cases/bg-mobile.jpg';
import bgTablet from '../../../assets/images/cases/bg-tablet.jpg';
import bgDesktop from '../../../assets/images/cases/bg-desktop.jpg';

export const CasesStyles = styled.section`
  padding: 13px 0 64px;
  
  background-image: url(${bgMobile});
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  
  @media (min-width: 768px) {
    padding: 35px 0 86px;
    background-image: url(${bgTablet});
  }

  @media (min-width: 1024px) {
    padding: 83px 0 140px;
    background-image: url(${bgDesktop});
  }
  
  h2 {
    text-align: center;
    
    padding-bottom: 24px;

    @media (min-width: 768px) {
      padding-bottom: 32px;
    }

    @media (min-width: 1024px) {
      padding-bottom: 40px;
    }
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }

    @media (min-width: 1024px) {
      gap: 95px;
    }
  }
  
  .textContainer {
    svg {
      width: 42px;
      height: 33px;
      margin-bottom: 12px;
      
      @media (min-width: 768px) {
        width: 42px;
        height: 33px;
        margin-bottom: 18px;
      }
      
      @media (min-width: 1024px) {
        width: 56px;
        height: 45px;
        margin-bottom: 24px;
      }
    }
    
    p {
      font-size: 16px;
      line-height: 24px;
      
      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 28px;
      }

      @media (min-width: 1024px) {
        font-size: 22px;
        line-height: 34px;
      }
    }
  }
  
  .menu {
    @media (min-width: 768px) {
      min-width: 300px;
    }

    @media (min-width: 1024px) {
      min-width: 420px;
    }
    
    button {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 12px;
      transition: 0.3s ease background-color, 0.3s ease color;
      
      svg {
        path {
          transition: 0.3s ease stroke;
        }
      }
      
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.3s ease background-color;
        width: 4px;
        height: 100%;
      }
      
      &.active {
        background-color: #FFEEE2;
        color: #FC812B;

        &::after {
          background-color: #FC812B;
        }

        svg {
          path {
            stroke: #FC812B;
          }
        }
      }
    }
  }
`;
