import React, { Fragment } from 'react';
import { LinksPathStyles } from './LinksPathStyles';
import { Link } from 'react-router-dom';

const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6 12L10 8L6 4" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

function LinksPath({ pathArr }: {pathArr: {path: string, name: string}[]}) {
  return (
    <LinksPathStyles className="linksPath">
      <div className="container">
        <ul>
          <li>
            <Link to="/" className="homeLink textSemiBold16" title="Go to main page">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M2 6.00016L8 1.3335L14 6.00016V13.3335C14 13.6871 13.8595 14.0263 13.6095 14.2763C13.3594 14.5264 13.0203 14.6668 12.6667 14.6668H3.33333C2.97971 14.6668 2.64057 14.5264 2.39052 14.2763C2.14048 14.0263 2 13.6871 2 13.3335V6.00016Z" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 14.6667V8H10V14.6667" stroke="#595959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>Home</span>
            </Link>
          </li>
          {arrow}
          {pathArr.map((pathPart, index) => {
            if (pathArr.length !== index + 1) {
              return (
                <Fragment key={pathPart.path}>
                  <li className="textSemiBold16">
                    <Link to={pathPart.path} title={`Go to ${pathPart.name} page`}>
                      <span>
                        {pathPart.name}
                      </span>
                    </Link>
                  </li>

                  {index + 1 < pathArr.length && arrow}
                </Fragment>
              );
            }

            return (
              <Fragment key={pathPart.path}>
                <li className="textSemiBold14">
                  <span>
                    {pathPart.name}
                  </span>
                </li>
              </Fragment>
            );
          })}
        </ul>
      </div>
    </LinksPathStyles>
  );
}

export default LinksPath;
