import styled from 'styled-components';

export const SquareCheckboxStyles = styled.div`
  .fieldName {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }
  
  .checkboxDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .checkboxText {
    display: flex;
    
  } 
  
  .checkboxWrapper {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 12px;
    cursor: pointer;
  }
  
  .customizedCheckbox {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 6px;

    transition: 0.3s ease background-color, 0.3s ease border-color;
    box-sizing: border-box;
  }

  .customizedCheckboxChecked {
    border: 1px solid var(--orange-1, #FC812B);
    background: #FFEBDD;
  }
  
  .checkboxText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .formErrorContainer {
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
