import React, { useState } from 'react';
import { ContactFormStyles } from './ContactFormStyles';
import { FormikHelpers, useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import SquareCheckbox from '../form/squareCheckbox/SquareCheckbox';
import { Api } from '../../api';
import InputField from '../form/input/InputField';
import Button from '../buttons/button/Button';

interface FormValues {
  name: string;
  email: string;
  privacyPolicy: boolean;
}

const initialValues: FormValues = {
  name: '',
  email: '',
  privacyPolicy: false,
};

interface Props {
  handleClose: () => void;
}

function ContactForm({ handleClose }: Props) {
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState<boolean>(false);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting, setFieldValue, setSubmitting,
  } = useFormik<FormValues>({
    initialValues: {
      name: '',
      email: '',
      privacyPolicy: false,
    },
    onSubmit: (values) => {
      if (submitError) {
        setSubmitError(false);
      }

      setSubmitting(true);

      if (values) {
        const data = {
          name: values.name,
          email: values.email,
          privacyPolicy: values.privacyPolicy,
        };

        // Todo: uncomment if register EmailJS
        // send(
        //   'service_zpbbvrv',
        //   'template_5tbezfq',
        //   data,
        //   'yTJ5l_KR9ivLMR648',
        // )
        //   .then((response) => {
        //     console.log('SUCCESS!', response.status, response.text);
        //   })
        //   .catch((err) => {
        //     console.log('FAILED...', err);
        //   });

        // TODO: uncomment when backend is done
        // Api.sendData(data)
        //   .then((res) => {
        //     if (res.statusCode >= 200 && res.statusCode < 300) {
        //       navigate('/thank-you');
        //     } else {
        //       setSubmitError(true);
        //     }
        //   });

        navigate('/thank-you');
        setSubmitting(false);
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Required field'),
      email: Yup.string().email('Email is not valid').required('Required field'),
      privacyPolicy: Yup.boolean().oneOf([true], 'Please accept the privacy policy and terms of use'),
    }),
  });

  return (
    <ContactFormStyles className="contactFormWrapper">
      <h2 className="textBold28">
        Let’s join us
      </h2>

      <form onSubmit={handleSubmit} className="form">
        <div className="fields">
          <InputField
            label="Name"
            name="name"
            onChange={handleChange}
            value={values.name}
            placeholder="Enter name"
            error={touched && touched.name && errors && errors.name ? errors.name : undefined}
          />

          <InputField
            label="E-mail"
            name="email"
            onChange={handleChange}
            value={values.email}
            placeholder="Enter e-mail"
            error={touched && touched.email && errors && errors.email ? errors.email : undefined}
          />

          <SquareCheckbox
            value={values.privacyPolicy}
            name="privacyPolicy"
            onChange={handleChange}
            error={touched && touched.privacyPolicy && errors && errors.privacyPolicy ? errors.privacyPolicy : undefined}
            showError
          >
            <span className="textBase14">
              By creating an account, you agree to our
              {' '}
              <Link className="link" to="/privacy-policy" target="_blank">Privacy Policy</Link>
              {' '}
              and
              {' '}
              <Link className="link" to="/terms-of-service" target="_blank">Terms of Use.</Link>
            </span>
          </SquareCheckbox>
        </div>

        <Button theme="mainFilled" onClick={handleSubmit} text="Submit" error={submitError} isLoading={isSubmitting} type="submit" disabled={isSubmitting} />

        {submitError && (
        <p className="error">
          *
          Oops! Something went wrong with the form submission
        </p>
        )}
      </form>
    </ContactFormStyles>
  );
}

export default ContactForm;
