import styled from 'styled-components';

export const ContactFormStyles = styled.div`
  border-radius: 4px;
  background: #FFF;
  
  .link {
    color: #FC812B;
  }
  
  h2 {
    margin-bottom: 24px;

    color: #000;

    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0.56px;
  }
  
  .form {
    max-width: 460px;
    
    .fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      margin-bottom: 32px;
    }
    
    .button {
      width: 100%;
    }
    
    .error {
      font-size: 11px;
      line-height: 16px;
      margin-top: 4px;
      color: #ff3333;
    }
  }
`;
