import styled from 'styled-components';

export const HeaderStyles = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  
  padding: 12px 0;

  border-bottom: 1px solid var(--Stroke, #EEE);
  background: #FFF;
  
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .buttonsWrapper {
    display: flex;
    gap: 48px;
    
    .button {
      min-width: 120px;
      
      @media (min-width: 1024px) {
        min-width: 160px;
      }
    }
  }
`;
