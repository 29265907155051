import styled from 'styled-components';

export const HowToUseStyles = styled.section`
  overflow: hidden;
  
  h2 {
    margin-bottom: 32px;
    
    text-align: center;
    
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 52px;
    }
  }
  
  .content {
    margin-bottom: 32px;
    
    @media (min-width: 768px) {
      margin-bottom: 40px;
    }

    @media (min-width: 1024px) {
      margin-bottom: 52px;
    }
    
    .itemWrapper {
      &:not(:last-child) {
        margin-bottom: 32px;

        @media (min-width: 768px) {
          margin-bottom: 40px;
        }

        @media (min-width: 1024px) {
          margin-bottom: 52px;
        }
      }
      
      &.active {
        .item {
          opacity: 1;
        }
      }
      
      .item {
        display: flex;
        flex-direction: column-reverse;
        margin: auto;
        max-width: 343px;
        width: 100%;
        gap: 16px;
        
        opacity: 0;
        transition: 2.5s ease opacity;
        
        @media (min-width: 768px) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          max-width: 736px;
        }
        
        @media (min-width: 1110px) {
          max-width: 1110px;
        }
        
        &.item1 {
          .arrow {
            top: 70px;
            left: -30px;
          }

          @media (min-width: 768px) {
            flex-direction: row-reverse;

            .arrow {
              top: -47px;
              left: 55px;
              
              svg {
                height: 444px;
                width: auto;
              }
            }
          }
          
          @media (min-width: 1110px) {
            .arrow {
              top: -100px;
              left: 110px;
              
              svg {
                width: auto;
                height: 690.852px;
              }
            }
          }
        }

        &.item2 {
          .arrow {
            top: 73px;
            left: 52px;
            
            @media (min-width: 768px) {
              left: -310px;
              top: 40px;
            }
            
            @media (min-width: 1110px) {
              left: -500px;
              top: 45px;
              
              svg {
                width: auto;
                height: 558.133px;
              }
            }
          }
        }

        &.item3 {
          .arrow {
            top: 70px;
            left: -35px;

            @media (min-width: 768px) {
              left: 165px;
              top: 140px;
            }
            
            @media (min-width: 768px) {
              left: 165px;
              top: 130px;
              
              svg {
                height: 520px;
                width: auto;
              }
            }
          }

          @media (min-width: 768px) {
            flex-direction: row-reverse;
          }
        }
        
        
        .itemContent {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 12px;
          
          .arrow {
            position: absolute;
            
            svg:nth-child(1) {
              @media (min-width: 768px) {
                display: none;
              }
            }

            svg:nth-child(2) {
              display: none;
              
              @media (min-width: 768px) {
                display: block;
              }
            }
          }
          
          .textList {
            position: relative;
            li {
              &:not(:last-child) {
                margin-bottom: 8px;
              }
            }
          }
          
          .iconsList {
            position: relative;
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: repeat(5, 20px);
            align-items: start;
            row-gap: 12px;
            column-gap: 28px;

            @media (min-width: 768px) {
              column-gap: 40px;
            }
            
            @media (min-width: 1110px) {
              column-gap: 84px;
              row-gap: 16px;
              
              grid-template-columns: 133px 163px;
              grid-template-rows: repeat(5, 25px);
              justify-content: space-between;
              
              li:nth-child(1) {
                svg {
                  width: 116px;
                  height: 24px;
                }
              }
              
              li:nth-child(2) {
                svg {
                  width: 99px;
                  height: 23px;
                }
              }

              li:nth-child(3) {
                svg {
                  width: 59px;
                  height: 24px;
                }
              }

              li:nth-child(4) {
                svg {
                  width: 120px;
                  height: 24px;
                }
              }

              li:nth-child(5) {
                svg {
                  width: 126px;
                  height: 28px;
                }
              }
              
              li:nth-child(6) {
                svg {
                  width: 163px;
                  height: 24px;
                }
              }

              li:nth-child(7) {
                svg {
                  width: 133px;
                  height: 24px;
                }
              }

              li:nth-child(8) {
                svg {
                  width: 113px;
                  height: 24px;
                }
              }

              li:nth-child(9) {
                svg {
                  width: 103px;
                  height: 27px;
                }
              }
            }
          }
        }
        
        .number {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: rgba(254, 201, 164, 0.3);
          color: var(--orange-1, #FC812B);
          
          @media (min-width: 1110px) {
            width: 48px;
            height: 48px;
          }
        }
        
        picture {
          display: flex;
          justify-content: center;
          
          img {
            width: 100%;
            position: relative;
            z-index: 1;

            border-radius: 4px;
            border: 1px solid var(--Stroke, #EEE);

            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
            
            @media (min-width: 375px) {
              width: 343px;
              height: 245px;
            }
            
            @media (min-width: 768px) {
              width: 390px;
              height: 279px;
            }
            
            @media (min-width: 1110px) {
              width: 635px;
              height: 453px;
            }
          }
        }
      }
    }
  }
  
  .button {
    width: 100%;
    margin: auto;
    
    @media (min-width: 768px) {
      max-width: 390px;
    }

    @media (min-width: 1024px) {
      max-width: 350px;
    }
  }
`;
