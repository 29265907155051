import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 64px 0;
  
  @media (min-width: 768px) {
    padding: 86px 0;
  }

  @media (min-width: 1024px) {
    padding: 80px 0;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .termsWrapper {
    display: flex;
    gap: 24px;
  }
  
  .logoAndSocialMediasWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @media (min-width: 768px) {
      align-items: flex-start;
    }

    .socialMedias {
      display: flex;
      align-items: center;
      gap: 24px;

      a {
        transition: 0.3s ease transform;
        
        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }
`;
