import React, { useState } from 'react';
import QustIs from '../../components/mainPage/qustIs/QustIs';
import { MainPageStyles } from './MainPageStyles';
import Slogan from '../../components/mainPage/slogan/Slogan';
import SuitesFor from '../../components/mainPage/suitesFor/SuitesFor';
import Cases from '../../components/mainPage/cases/Cases';
import Banner from '../../components/mainPage/banner/Banner';
import HowToUse from '../../components/mainPage/howToUse/HowToUse';
import Modal from '../../components/modal/Modal';
import ContactForm from '../../components/contactForm/ContactForm';

function Main({ modalOpen, setModalOpen }: {modalOpen: boolean, setModalOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  return (
    <MainPageStyles>
      {modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <ContactForm handleClose={() => setModalOpen(false)} />
        </Modal>
      )}

      <Banner setModalOpen={setModalOpen} />
      <QustIs />
      <HowToUse />
      <SuitesFor />
      <Cases />
      <Slogan setModalOpen={setModalOpen} />
    </MainPageStyles>
  );
}

export default Main;
